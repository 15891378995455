import { forwardRef, useState } from 'react'
import type { KeyboardEvent } from 'react'
import {
	Input,
	ChakraProps,
	Flex,
	Text,
	Popover,
	PopoverTrigger,
	Button,
	PopoverContent,
	PopoverCloseButton,
	PopoverBody,
	ButtonProps,
	InputProps,
	InputRightElement,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import {
	useYtaMutation,
	useYtaQuery,
	geoSearch,
	areaFeatures,
} from '@alexanderathoodly/data-models'
import type {
	PhotonFeature,
	NominatimFeature,
} from '@alexanderathoodly/data-models'

const SelectedLocationItem: React.FC<
	{
		item: NominatimFeature
		onDeselect: (deselectedItems: string[]) => void
	} & ChakraProps &
		ButtonProps
> = ({ item, onDeselect, ...rest }) => {
	return (
		<Button
			size="sm"
			borderRadius="full"
			alignItems="center"
			px={3}
			gap={3}
			onClick={() => {
				onDeselect([`${item.properties.osm_type}${item.properties.osm_id}`])
			}}
			{...rest}
		>
			<Text>{item.properties.namedetails.name}</Text>
			<Text _hover={{ color: 'blue.800' }}>x</Text>
		</Button>
	)
}

const SelectedLocations: React.FC<{
	selectedOsmIds: string[]
	onDeselect: (deselectedItems: string[]) => void
}> = ({ selectedOsmIds, onDeselect }) => {
	const { data: areaFeatureColection } = useYtaQuery({
		...areaFeatures({ ids: selectedOsmIds }),
	})
	const { t } = useTranslation()
	if (!areaFeatureColection?.features.length) {
		return null
	}
	const areas = areaFeatureColection.features
	const first = areas[0]
	return (
		<Flex gap={2}>
			{first != undefined && (
				<SelectedLocationItem
					key={first.properties.osm_id}
					item={first}
					onDeselect={onDeselect}
					variant="ghost"
					backgroundColor="blue.50"
				/>
			)}
			{selectedOsmIds.length > 1 && (
				<Popover>
					{() => (
						<>
							<PopoverTrigger>
								<Button
									size="sm"
									borderRadius="full"
									variant="ghost"
									backgroundColor="blue.50"
								>
									<Text fontSize="sm">+ {selectedOsmIds.slice(1).length}</Text>
								</Button>
							</PopoverTrigger>
							<PopoverContent>
								<PopoverCloseButton />
								<PopoverBody p={4}>
									<Flex gap="0.5rem" flexWrap="wrap">
										{areas.slice(1).map((feature) => {
											return (
												<SelectedLocationItem
													key={feature.properties.osm_id}
													item={feature}
													onDeselect={onDeselect}
												/>
											)
										})}
									</Flex>
									<Button
										mt={4}
										size="sm"
										variant="outline"
										onClick={() => {
											onDeselect(selectedOsmIds)
										}}
									>
										{t('Rensa')}
									</Button>
								</PopoverBody>
							</PopoverContent>
						</>
					)}
				</Popover>
			)}
		</Flex>
	)
}

const ResultListItem: React.FC<
	{
		item: PhotonFeature
		onSelect: (item: PhotonFeature) => void
	} & ChakraProps
> = ({ item, onSelect, ...rest }) => {
	const { name, locality, district, city, county, osm_id } = item.properties

	return (
		<Flex
			direction="column"
			px={3}
			py={1}
			backgroundColor="white"
			cursor="pointer"
			_hover={{ backgroundColor: 'blue.50' }}
			onClick={() => {
				onSelect(item)
			}}
			data-test-id={`area-search-result-item-${osm_id}`}
			{...rest}
		>
			<Text fontWeight="semibold">{name}</Text>
			<Text fontSize="xs">
				{[locality, district, city, county].filter((i) => !!i).join(', ')}
			</Text>
		</Flex>
	)
}

const SearchResultList: React.FC<{
	results: PhotonFeature[]
	onSelect: (item: PhotonFeature) => void
	currentItemIndex: number | null
}> = ({ results, onSelect, currentItemIndex }) => {
	const { t } = useTranslation()

	if (!results.length) {
		return null
	}
	const currentItemOsmId =
		currentItemIndex != null
			? results[currentItemIndex]?.properties.osm_id
			: null
	const groupedResults = results.reduce(
		(group: { [key: string]: PhotonFeature[] }, result) => {
			const type = result.properties.osm_value
			group[type] = group[type] ? group[type]!.concat(result) : [result]
			return group
		},
		{}
	)

	return (
		<Flex
			position="absolute"
			top={7}
			left={0}
			zIndex={1000}
			width="100%"
			direction="column"
		>
			{Object.keys(groupedResults).map((key) => {
				return (
					<Flex key={key} direction="column" backgroundColor="white">
						<Flex px={3}>
							<Text fontWeight="semibold" color="blue.500">
								{t(key)}
							</Text>
						</Flex>
						{groupedResults[key]!.map((res) => {
							return (
								<ResultListItem
									item={res}
									key={res.properties.osm_id}
									onSelect={onSelect}
									backgroundColor={
										currentItemOsmId != null &&
										currentItemOsmId == res.properties.osm_id
											? 'blue.50'
											: 'white'
									}
								/>
							)
						})}
					</Flex>
				)
			})}
		</Flex>
	)
}

const useGeoSearch = (onChange: (value: string[]) => void, value: string[]) => {
	const [searchValue, setSearchValue] = useState('')
	const [searchResult, setSearchResult] = useState<PhotonFeature[]>([])
	const [currentItemIndex, setCurrentItemIndex] = useState<number | null>(null)
	const sortedSearchResult = searchResult.sort((a, b) => {
		if (a.properties.osm_value > b.properties.osm_value) {
			return 1
		} else if (a.properties.osm_value == b.properties.osm_value) {
			return 0
		} else {
			return -1
		}
	})

	//Using a mutation insted of query as we don't want this to be stored, just keep it in a state.
	const searchMutation = useYtaMutation(geoSearch, {
		onSuccess: (data) => {
			setSearchResult(data.features)
		},
	})

	function onSearchInputChange(search: string): void {
		searchMutation.reset()
		setSearchValue(search)
		if (search.length > 2) {
			//TODO: country needs to be dynamic, but we need to decide how and where we get country
			searchMutation.mutate({ search, country: 'SE' })
			return
		}
		setSearchResult([])
	}

	function onSelect(item: PhotonFeature): void {
		const searchValue = `${item.properties.osm_type}${item.properties.osm_id}`
		if (!value.find((s) => s === searchValue)) {
			onChange([searchValue, ...value])
		}
		setSearchValue('')
		setSearchResult([])
	}

	function onKeyDown(event: KeyboardEvent<HTMLDivElement>): void {
		if (event.key == 'ArrowUp') {
			event.stopPropagation()
			if (currentItemIndex == null) {
				return
			} else {
				if (currentItemIndex == 0) {
					return
				}
				setCurrentItemIndex(currentItemIndex - 1)
			}
		}
		if (event.key == 'ArrowDown') {
			event.stopPropagation()
			if (currentItemIndex == null) {
				setCurrentItemIndex(0)
			} else {
				if (currentItemIndex + 1 == searchResult.length) {
					return
				}
				setCurrentItemIndex(currentItemIndex + 1)
			}
		}
		if (event.key == 'Enter') {
			if (currentItemIndex != null) {
				event.stopPropagation()
				const selectedFeature = sortedSearchResult[currentItemIndex]
				if (selectedFeature) {
					onSelect(selectedFeature)
					setCurrentItemIndex(null)
				}
			}
			// Allow event bubbling if currentItemIndex == null
		}
	}

	function onDeselect(deselectedItems: string[]): void {
		const current = new Set(value)
		current.forEach((item) => {
			if (deselectedItems.includes(item)) {
				current.delete(item)
			}
		})
		onChange([...current])
	}

	return {
		searchValue,
		onSearchInputChange,
		searchResult,
		onSelect,
		value,
		onDeselect,
		onKeyDown,
		currentItemIndex,
	}
}

export type SelectionValueItem = { display: string; value: string }

type GeoSearchFieldProps = {
	placeholder?: string | undefined
	onChange: (value: string[]) => void
	value: string[] //string value being osm_type + osm_id
}

const GeoSearchField = forwardRef<
	HTMLInputElement,
	ChakraProps & GeoSearchFieldProps & Omit<InputProps, 'value'>
>(({ onChange, value, ...rest }, ref) => {
	const {
		searchValue,
		searchResult,
		onSelect,
		onSearchInputChange,
		onDeselect,
		onKeyDown,
		currentItemIndex,
	} = useGeoSearch(onChange, value)

	return (
		<Flex position="relative" h={10} w={{ base: 'inherit' }}>
			<Input
				ref={ref}
				value={searchValue}
				onKeyDown={onKeyDown}
				onChange={(e) => onSearchInputChange(e.currentTarget.value)}
				minW={{ md: 80 }}
				{...rest}
			/>
			<InputRightElement w="min-content" mr={2}>
				{value?.length > 0 && (
					<SelectedLocations selectedOsmIds={value} onDeselect={onDeselect} />
				)}
			</InputRightElement>
			<SearchResultList
				results={searchResult}
				onSelect={onSelect}
				currentItemIndex={currentItemIndex}
			/>
		</Flex>
	)
})

GeoSearchField.displayName = 'GeoSearchField'

export { GeoSearchField }
