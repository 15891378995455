import TimImg from '#/team/Tim_202111_resized.jpg'
import ViktorImg from '#/team/Viktor_202111_resized.jpg'
import ParImg from '#/team/Par_202111_resized.jpg'
import DavidImg from '#/team/David_202404_resized.jpg'
import { useTranslation } from 'react-i18next'

interface Team {
	img: string
	positionTitle: string
	tags: string[]
	description: string
}

export const TEAM: { [name: string]: Team } = {
	'Tim Andersson': {
		img: TimImg,
		positionTitle: 'Grundare &  CCO',
		tags: ['30+ arbetsplatser', 'CBD-expert'],
		description:
			'Tim är grundare och ansvarig för våra relationer med allt utbud. Han är också känd för att hjälpa stora techbolag att hitta nya hem.',
	},
	'Viktor Söderström': {
		img: ViktorImg,
		positionTitle: 'Grundare & CEO',
		tags: ['50+ arbetsplatser', 'CBD-expert'],
		description:
			'En av grundarna och vår kära VD med erfarenhet av större och mer komplicerade flyttar',
	},
	'Pär Lövgren': {
		img: ParImg,
		positionTitle: 'Kontorsexpert',
		tags: ['coworking', 'Lyssnare'],
		description:
			'Pär förstår våra kunders behov samt utmaningar. Han kan ge exempel på hur vi hjälpt andra bolag med samma behov och har alltid en bra lösning i rockärmen.',
	},
	'David Kostadinovic': {
		img: DavidImg,
		positionTitle: 'Kontorsexpert',
		tags: ['kontorshotell', 'snabb'],
		description:
			'David jobbar över alla marknader och kan allt mellan himmel och jord om de hundratals kontorshotell som finns i Sverige.',
	},
}

const NAMES = Object.keys(TEAM)

export function useManagerInfoForHomepage(): Array<
	Team & { fullName: string }
> {
	const { t } = useTranslation()
	return NAMES.map((name) => {
		return {
			...TEAM[name]!,
			fullName: name,
			positionTitle: t(TEAM[name]?.positionTitle || ''),
			tags: TEAM[name]?.tags.map((tag) => t(tag)) || [],
			description: t(TEAM[name]?.description || ''),
		}
	})
}

export function getPersonOfTheDay(): { img: string; name: string } {
	const date = new Date().getDate()
	const name = NAMES[date % NAMES.length]!
	return { img: TEAM[name]!.img, name: name.replace(/ .*/, '') }
}
