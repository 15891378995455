import { useCallback, useEffect, useMemo, useRef } from 'react'
import type { FC, ReactElement, MutableRefObject } from 'react'
import {
	Button,
	Flex,
	Input,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	FormControl,
	FormLabel,
	ChakraProps,
	Select,
	PopoverAnchor,
	InputGroup,
} from '@chakra-ui/react'
import { useQuery } from 'react-query'
import {
	Controller,
	FieldValues,
	useForm as useHookForm,
} from 'react-hook-form'
import type {
	Control,
	ControllerRenderProps,
	Path,
	ControllerFieldState,
	UseFormStateReturn,
} from 'react-hook-form'
import type { Location as HistoryLocation } from 'history'
import {
	MultiSelectDropdown,
	MultiInputBox,
	FilterIcn,
	ArrowDownIcn,
} from '@alexanderathoodly/ui-library'
import { servicesQuery } from '../coworking_location/queries'
import { SERVICES } from '../coworking_location/services'
import { useUpdateSerpFilters } from './updateFilters'
import { matchPath, useHistory } from 'react-router-dom'
import type { match } from 'react-router-dom'
import { extractCoworkingSerpParams } from './utils'
import serpRoute from './route'
import { sendGA4Event } from '~/analytics/ga'
import {
	parseFilterType,
	FILTER_TYPE_ALL,
	FILTER_TYPE_OFFICE,
	FILTER_TYPE_MEMBERSHIP,
} from './urlGenerator'
import type { FILTER_TYPE_CHOICE } from './urlGenerator'
import { BudgetFilterPopover, BudgetSlider } from './budgetslider'
import { useTranslation } from 'react-i18next'
import { GeoSearchField } from '~/forms/geosearch'
import { useSortSelector } from './utils'

// Location can come from the window object or from the history library, they have many fields in common but not all
type CommonLocation = Omit<HistoryLocation, 'state'>

type SerpFilterFormValues = {
	areaCodes: string[]
	services: string[]
	maxBudget: number | null
	minWorkspaces: number | null
	filterType: FILTER_TYPE_CHOICE
}

const getMatch = (location: CommonLocation) =>
	matchPath<{ city: string; neighborhood: string }>(
		location.pathname,
		serpRoute
	)

/**
 * The form for the SERP is not very complex but the sync is a bit complex as it requires sometimes to be out of sync
 * this hook takes care of the sync with the URL as well as the initialization on load for the form and its values
 * it returns the react hook form instance without any change
 */
const useSerpFilterForm = () => {
	const getIdealFormState: (
		match: match<{ city: string; neighborhood: string }>
	) => SerpFilterFormValues = useCallback((match) => {
		const params = new URLSearchParams(location.search)
		const serpParams = extractCoworkingSerpParams(
			params,
			match.params.city,
			match.params.neighborhood
		)

		return {
			areaCodes: serpParams.areas,
			services: serpParams.services || [],
			maxBudget: serpParams.budget || null,
			minWorkspaces: serpParams.workspaces || null,
			filterType: serpParams.type
				? parseFilterType(serpParams.type)
				: FILTER_TYPE_ALL,
		}
	}, [])
	const history = useHistory()
	const form = useHookForm<SerpFilterFormValues>({
		defaultValues: {
			areaCodes: [],
			services: [],
			maxBudget: null,
			minWorkspaces: null,
			filterType: FILTER_TYPE_ALL,
		},
	})
	const { reset } = form
	// Ideally we'd like to set the default values directly but that would require always be guaranteed to have the business area information
	// So instead we wait to have it (so getIdealFormState gets run again) and reset the form to match the URL
	useEffect(() => {
		const match = getMatch(history.location)
		if (match) {
			reset(getIdealFormState(match), { keepDefaultValues: true })
		}
	}, [history, getIdealFormState, reset])
	// If a navigation is not triggered directly by the form (for instance if the user goes back / forward) we need to manually sync the form
	// with the URL, this is done by listening to the history and calling reset
	useEffect(() => {
		return history.listen((l, a) => {
			const match = getMatch(l)
			if (a === 'POP' && match) {
				reset(getIdealFormState(match), { keepDefaultValues: true })
			}
		})
	}, [history, reset, getIdealFormState])
	return form
}

type ControllerFullRenderProps<
	TFieldValues extends FieldValues,
	FieldName extends Path<TFieldValues>
> = {
	field: ControllerRenderProps<TFieldValues, FieldName>
	fieldState: ControllerFieldState
	formState: UseFormStateReturn<TFieldValues>
}

type SpecializedController<
	TFieldValues extends FieldValues,
	FieldName extends Path<TFieldValues>,
	AddRenderProps = unknown,
	P = unknown
> = FC<
	P & {
		control: Control<TFieldValues>
		render: (
			props: ControllerFullRenderProps<TFieldValues, FieldName> & AddRenderProps
		) => ReactElement
	}
>

/**
 * Given a render function and an object with additional properties, return a new
 * render function that includes the additional properties
 */
const useEnhancedRender = <
	TAddProps extends Record<string, unknown>,
	TFieldValues extends FieldValues,
	FieldName extends Path<TFieldValues>
>(
	render: (
		props: ControllerFullRenderProps<TFieldValues, FieldName> & TAddProps
	) => ReactElement,
	addProps: TAddProps
): ((
	props: ControllerFullRenderProps<TFieldValues, FieldName>
) => ReactElement) => {
	return useCallback(
		(props) => {
			return render({ ...props, ...addProps })
		},
		// ESLint does not understand that ...Object.values catches the `addProps` dependency
		// TODO: Find another way to satisfy the dependency?
		// eslint-disable-next-line
		[render, ...Object.values(addProps)]
	)
}

const ServiceFilterController: SpecializedController<
	SerpFilterFormValues,
	'services',
	{ serviceOptions: { value: string; display: string }[] }
> = ({ control, render }) => {
	const { t } = useTranslation()
	const services = useQuery(servicesQuery)
	const serviceOptions = useMemo(() => {
		return services.data
			? services.data
					.filter((service) => service.name in SERVICES)
					.map((service) => {
						return {
							value: service.name,
							display: t(SERVICES[service.name]!.name),
						}
					})
			: []
	}, [services.data, t])
	const enhancedRender = useEnhancedRender(render, { serviceOptions })
	return (
		<Controller name="services" control={control} render={enhancedRender} />
	)
}

const BudgetFilterController: SpecializedController<
	SerpFilterFormValues,
	'maxBudget',
	{
		isOpen: boolean
		onClose: () => void
		onOpen: () => void
		initialFocusRef: MutableRefObject<any>
	}
> = ({ control, render }) => {
	const { isOpen, onClose, onOpen } = useDisclosure()
	const initialFocusRef = useRef()
	const enhancedRender = useEnhancedRender(render, {
		isOpen,
		onClose,
		onOpen,
		initialFocusRef,
	})

	return (
		<Controller name="maxBudget" control={control} render={enhancedRender} />
	)
}

const HIDE_LABEL_WHEN_PLACEHOLDER_SHOWN = {
	'.form-control:has(input:placeholder-shown) &': { display: 'none' },
}

export const FiltersBar: FC<ChakraProps> = ({ ...rest }) => {
	const { t } = useTranslation()
	const updateFilters = useUpdateSerpFilters()
	const { control, register, handleSubmit, watch, setValue, getValues } =
		useSerpFilterForm()
	const { isOpen, onOpen, onClose } = useDisclosure()
	useEffect(() => {
		const subscription = watch(updateFilters)
		return () => subscription.unsubscribe()
	}, [watch, updateFilters])

	return (
		<Flex
			as="form"
			onSubmit={handleSubmit(updateFilters)}
			gap="10px"
			display={{ base: 'none', md: 'flex' }}
			{...rest}
		>
			<FormControl width="auto" className="form-control">
				<FormLabel variant="on-border" sx={HIDE_LABEL_WHEN_PLACEHOLDER_SHOWN}>
					{t('Antal platser')}
				</FormLabel>
				<Input
					placeholder={t('Antal platser')}
					size="md"
					maxW={40}
					type="number"
					borderColor="grey.300"
					_placeholder={{
						color: 'grey.800',
						fontWeight: 'normal',
					}}
					{...register('minWorkspaces')}
					onChange={(event) => {
						void register('minWorkspaces').onChange(event)
						sendGA4Event('site_interaction', {
							type: 'filter',
							budget: getValues('maxBudget'),
							workspaces: getValues('minWorkspaces'),
						})
					}}
				/>
			</FormControl>
			<BudgetFilterController
				control={control}
				render={({
					field: { value, onChange },
					isOpen,
					onClose,
					onOpen,
					initialFocusRef,
				}) => {
					return (
						<BudgetFilterPopover
							isOpen={isOpen}
							onClose={onClose}
							formValue={value}
							onChange={onChange}
							initialFocusRef={initialFocusRef}
						>
							<PopoverAnchor>
								<FormControl width="auto" className="form-control">
									<FormLabel
										variant="on-border"
										sx={HIDE_LABEL_WHEN_PLACEHOLDER_SHOWN}
									>
										{t('Budget / mån.')}
									</FormLabel>
									<Input
										placeholder={t('Budget / mån')}
										size="md"
										maxW={40}
										type="number"
										borderColor="grey.300"
										_placeholder={{ color: 'grey.800', fontWeight: 'normal' }}
										onFocus={onOpen}
										onBlur={onClose}
										ref={initialFocusRef}
										value={value || ''}
										onChange={(event) => {
											onChange(event)
											sendGA4Event('site_interaction', {
												type: 'filter',
												budget: getValues('maxBudget'),
												workspaces: getValues('minWorkspaces'),
											})
										}}
									/>
								</FormControl>
							</PopoverAnchor>
						</BudgetFilterPopover>
					)
				}}
			/>
			<Controller
				control={control}
				name="areaCodes"
				defaultValue={[]}
				render={({ field: { onChange, value } }) => {
					return (
						<FormControl width="auto">
							<FormLabel
								variant="on-border"
								display={value.length ? '' : 'none'}
							>
								{t('Område')}
							</FormLabel>
							<InputGroup mx="auto">
								<GeoSearchField
									onChange={onChange}
									value={value}
									placeholder={t('Skriv stad eller område') || ''}
									borderColor="grey.300"
									_placeholder={{
										color: 'grey.800',
										fontWeight: 'normal',
									}}
								/>
							</InputGroup>
						</FormControl>
					)
				}}
			/>
			<Button
				onClick={onOpen}
				display="inline-flex"
				aria-label="Filtrera"
				variant="ghost"
				fontSize="sm"
				minW={0}
			>
				{t('Fler filter')}
			</Button>
			<Modal isOpen={isOpen} onClose={onClose} size="sm">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader></ModalHeader>
					<ModalCloseButton />
					<ModalBody display="flex" gap="20px" flexDirection="column">
						<FormControl width="auto" className="form-control">
							<FormLabel
								variant="on-border"
								sx={HIDE_LABEL_WHEN_PLACEHOLDER_SHOWN}
							>
								{t('Kontorstyp')}
							</FormLabel>
							<Select
								size="md"
								maxW={40}
								multiple={false}
								borderColor="grey.300"
								{...register('filterType')}
							>
								{[
									{ display: t('Alla'), value: FILTER_TYPE_ALL },
									{ display: t('Kontor'), value: FILTER_TYPE_OFFICE },
									{ display: t('Medlemskap'), value: FILTER_TYPE_MEMBERSHIP },
								].map((opt) => (
									<option key={opt.value} value={opt.value}>
										{opt.display}
									</option>
								))}
							</Select>
						</FormControl>
						<ServiceFilterController
							control={control}
							render={({ serviceOptions, field: { value, onChange } }) => (
								<FormControl>
									<FormLabel
										variant="on-border"
										display={value.length ? '' : 'none'}
									>
										{t('Bekvämligheter')}
									</FormLabel>
									<MultiSelectDropdown
										options={serviceOptions}
										multiple
										selected={value}
										placeholder={t('Bekvämligheter')}
										onChange={onChange}
										onClear={() => setValue('services', [])}
									/>
								</FormControl>
							)}
						/>
						<SortField />
					</ModalBody>
					<ModalFooter>
						<Button
							onClick={() => {
								onClose()
							}}
						>
							{t('Stäng')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Flex>
	)
}

export const FiltersModalControl: FC<ChakraProps> = ({ ...rest }) => {
	const { t } = useTranslation()
	const updateFilters = useUpdateSerpFilters()
	const { isOpen, onOpen, onClose } = useDisclosure()
	const {
		control,
		register,
		handleSubmit,
		watch,
		formState: { isDirty },
		setValue,
		reset,
		getValues,
	} = useSerpFilterForm()
	useEffect(() => {
		const subscription = watch(updateFilters)
		return () => subscription.unsubscribe()
	}, [watch, updateFilters])
	return (
		<>
			<Button
				leftIcon={
					<FilterIcn
						w={5}
						h={5}
						strokeWidth={2}
						style={{ display: 'inline-block' }}
						color={isDirty ? 'blue.500' : 'black'}
					/>
				}
				iconSpacing="2px"
				onClick={onOpen}
				display={{ base: 'inline-flex', md: 'none' }}
				aria-label="Filtrera"
				variant="link"
				flex={1}
				textAlign="right"
				fontWeight="semibold"
				color="blackAlpha.800"
				textDecoration={isDirty ? 'underline' : 'none'}
				{...rest}
			>
				{t('Sökfilter')}
			</Button>
			<Modal isOpen={isOpen} onClose={onClose} size="full">
				<ModalOverlay />
				<ModalContent as="form" onSubmit={handleSubmit(updateFilters)}>
					<ModalHeader>{t('Filtrera din sökning')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody
						display="flex"
						gap="20px"
						flexDirection="column"
						overflow="scroll"
					>
						<FormControl>
							<FormLabel>{t('Antal platser')}</FormLabel>
							<Input
								placeholder={t('Antal platser')}
								size="md"
								type="number"
								{...register('minWorkspaces', {
									setValueAs: (v) => (v ? Number(v) : null),
								})}
							/>
						</FormControl>
						<BudgetFilterController
							control={control}
							render={({ field: { value, onChange } }) => {
								return (
									<>
										<FormControl>
											<FormLabel>{t('Budget / mån.')}</FormLabel>
											<Input
												placeholder={t('Budget / mån')}
												size="md"
												type="number"
												{...register('maxBudget')}
												value={value || undefined}
												onChange={(event) => {
													void register('maxBudget').onChange(event)
													sendGA4Event('site_interaction', {
														type: 'filter',
														budget: getValues('maxBudget'),
														workspaces: getValues('minWorkspaces'),
													})
												}}
											/>
										</FormControl>
										<BudgetSlider formValue={value} onChange={onChange} />
									</>
								)
							}}
						/>
						<Controller
							control={control}
							name="areaCodes"
							defaultValue={[]}
							render={({ field: { onChange, value } }) => {
								return (
									<FormControl>
										<FormLabel>{t('Område')}</FormLabel>
										<InputGroup>
											<GeoSearchField
												onChange={onChange}
												value={value}
												placeholder={t('Skriv stad eller område') || ''}
											/>
										</InputGroup>
									</FormControl>
								)
							}}
						/>
						<ServiceFilterController
							control={control}
							render={({ serviceOptions, field: { value, onChange } }) => (
								<FormControl>
									<FormLabel>{t('Bekvämligheter')}</FormLabel>
									<MultiInputBox
										options={serviceOptions}
										multiple
										selected={value}
										onChange={onChange}
										onClear={() => setValue('services', [])}
									/>
								</FormControl>
							)}
						/>
						<FormControl width="auto" className="form-control">
							<FormLabel
								variant="on-border"
								sx={HIDE_LABEL_WHEN_PLACEHOLDER_SHOWN}
							>
								{t('Kontorstyp')}
							</FormLabel>
							<Select size="md" multiple={false} {...register('filterType')}>
								{[
									{ display: t('Alla'), value: FILTER_TYPE_ALL },
									{ display: t('Kontor'), value: FILTER_TYPE_OFFICE },
									{ display: t('Medlemskap'), value: FILTER_TYPE_MEMBERSHIP },
								].map((opt) => (
									<option key={opt.value} value={opt.value}>
										{opt.display}
									</option>
								))}
							</Select>
						</FormControl>
					</ModalBody>
					<ModalFooter
						display="flex"
						position="sticky"
						gap="12px"
						bottom={0}
						backgroundColor="white"
					>
						<Button
							variant="outline"
							flex="1"
							onClick={() => {
								reset()
							}}
						>
							{t('Rensa Filter')}
						</Button>
						<Button
							onClick={() => {
								onClose()
							}}
							type="submit"
							flex="1"
						>
							{t('Visa resultat')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export const SortField: FC<ChakraProps> = ({ ...rest }) => {
	const { t } = useTranslation()
	const { sortMode, onChangeSortSelector, sortOptions } = useSortSelector()
	return (
		<FormControl display="flex" width="max-content" flex={1} {...rest}>
			<FormLabel variant="on-border">{t('Sortering')}</FormLabel>
			<Select
				value={sortMode}
				onChange={(e) => onChangeSortSelector(e.target.value)}
				icon={<ArrowDownIcn strokeWidth="3.5" />}
				iconSize="10px"
				borderColor="grey.300"
			>
				{sortOptions.map((option) => (
					<option key={option.value} value={option.value}>
						{option.text}
					</option>
				))}
			</Select>
		</FormControl>
	)
}
