import { PropsWithChildren, useMemo } from 'react'
import type { FC } from 'react'
import {
	Slider,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	Box,
	Popover,
	PopoverContent,
	PopoverArrow,
	PopoverCloseButton,
	PopoverProps,
	SliderMark,
	Spinner,
} from '@chakra-ui/react'
import { ChartBar, VerticalBarChart } from '@alexanderathoodly/ui-library'
import { useCoworkingSerpParams } from './utils'
import { useQuery } from 'react-query'
import { getCoworkingSearchResultQuery } from '~/queries/coworking_search_result'
import {
	FILTER_TYPE_ALL,
	FILTER_TYPE_MEMBERSHIP,
	FILTER_TYPE_OFFICE,
} from './urlGenerator'

function useBudgetChart() {
	const criteria = useCoworkingSerpParams()
	const { data } = useQuery(
		getCoworkingSearchResultQuery({
			...criteria,
			budget: undefined,
			type:
				criteria.type !== FILTER_TYPE_OFFICE &&
				criteria.type !== FILTER_TYPE_MEMBERSHIP
					? undefined
					: criteria.type,
		})
	)
	return useMemo(() => {
		const numberOfBars = 10
		const barValues: {
			minValue: number
			maxValue: number
			height: string
			count: number
		}[] = []
		if (data && data.max_price_of_all && data.min_price_of_all) {
			const minPriceOfAll =
				Math.ceil(
					Math.min(
						...data.locations.map((location) => {
							const membershipPrice =
								location.min_rent_membership?.price || Infinity
							const solutionPrice =
								location.min_rent_solution?.price || Infinity
							if (criteria.type) {
								if (criteria.type == FILTER_TYPE_MEMBERSHIP) {
									return criteria.workspaces
										? membershipPrice * criteria.workspaces
										: membershipPrice
								}

								if (criteria.type == FILTER_TYPE_OFFICE) {
									return solutionPrice
								}
							}
							return Math.min(
								criteria.workspaces
									? membershipPrice * criteria.workspaces
									: membershipPrice,
								solutionPrice
							)
						})
					) / 100
				) * 100
			const maxPriceOfAll =
				Math.ceil(
					Math.max(
						...data.locations.map((location) => {
							const membershipPrice = location.min_rent_membership?.price || 0
							const solutionPrice = location.min_rent_solution?.price || 0
							if (criteria.type) {
								if (criteria.type == FILTER_TYPE_MEMBERSHIP) {
									return criteria.workspaces
										? membershipPrice * criteria.workspaces
										: membershipPrice
								}

								if (criteria.type == FILTER_TYPE_OFFICE) {
									return solutionPrice
								}
							}
							return Math.max(
								criteria.workspaces
									? membershipPrice * criteria.workspaces
									: membershipPrice,
								solutionPrice
							)
						})
					) / 100
				) * 100 // TODO: This value should be sent by our backend if we face performance issue.

			const stepBetweenBars = (maxPriceOfAll - minPriceOfAll) / numberOfBars

			for (let i = 1; i <= numberOfBars; i++) {
				const minValue =
					i == 1 ? minPriceOfAll : minPriceOfAll + stepBetweenBars * (i - 1) + 1
				const maxValue = minPriceOfAll + stepBetweenBars * i
				barValues.push({ minValue, maxValue, height: '0%', count: 0 })
			}

			data.locations.forEach((location) => {
				let minSolutionPrice: number | undefined
				let minMembershipPrice: number | undefined
				if (!criteria.type || criteria.type == FILTER_TYPE_ALL) {
					minSolutionPrice = location.min_rent_solution
						? location.min_rent_solution.price
						: undefined
					minMembershipPrice = location.min_rent_membership
						? criteria.workspaces
							? location.min_rent_membership.price * criteria.workspaces
							: location.min_rent_membership.price
						: undefined
				} else if (
					criteria.type == FILTER_TYPE_OFFICE &&
					location.min_rent_solution
				) {
					minSolutionPrice = location.min_rent_solution.price
				} else if (
					criteria.type == FILTER_TYPE_MEMBERSHIP &&
					location.min_rent_membership
				) {
					minMembershipPrice = criteria.workspaces
						? location.min_rent_membership.price * criteria.workspaces
						: location.min_rent_membership.price
				}

				barValues.forEach((barValue, index) => {
					if (minSolutionPrice) {
						if (
							barValue.minValue <= minSolutionPrice &&
							minSolutionPrice <= barValue.maxValue
						) {
							barValues[index]!.count++
						}
					}
					if (minMembershipPrice) {
						if (
							barValue.minValue <= minMembershipPrice &&
							minMembershipPrice <= barValue.maxValue
						) {
							barValues[index]!.count++
						}
					}
				})
			})

			const totalNumber = barValues.reduce(
				(accum, { count }) => accum + count,
				0
			)

			barValues.forEach(
				(value) =>
					(value.height = `${
						(value.count / totalNumber) * 100 * 4 // multiple by 4 to make bars bigger
					}%`)
			)

			return {
				barValues,
				minPriceOfAll,
				maxPriceOfAll,
			}
		}
		return {
			barValues,
			minPriceOfAll: undefined,
			maxPriceOfAll: undefined,
		}
	}, [data, criteria.type, criteria.workspaces])
}

interface BudgetChartBarProps {
	height: number | string
	maxValue: number
	sliderValue: number
}

const BudgetChartBar: FC<BudgetChartBarProps> = ({
	height,
	maxValue,
	sliderValue,
}) => {
	const color = useMemo(() => {
		if (maxValue <= sliderValue) {
			return { backgroundImage: 'linear-gradient(#007aff,#F2F8FF)' } // blue.700 to blue.50
		}
		return { backgroundColor: 'gray.400' }
	}, [maxValue, sliderValue])

	return <ChartBar height={height} {...color} />
}

interface BudgetChartProps {
	sliderValue: number
}

const BudgetChart: FC<BudgetChartProps> = ({ sliderValue }) => {
	const { minPriceOfAll, barValues } = useBudgetChart()
	if (!minPriceOfAll) {
		return <></>
	}
	return (
		<VerticalBarChart height={24}>
			<BudgetChartBar
				height={0}
				maxValue={minPriceOfAll}
				sliderValue={sliderValue}
			/>
			{barValues.map((value, key) => (
				<BudgetChartBar
					key={`budget-chart-bar-${key}`}
					height={value.height}
					maxValue={value.maxValue}
					sliderValue={sliderValue}
				/>
			))}
		</VerticalBarChart>
	)
}

interface BudgetSliderProps {
	formValue: number | null
	onChange: (...event: any[]) => void
}

export const BudgetSlider: FC<BudgetSliderProps> = ({
	formValue,
	onChange,
}) => {
	const { minPriceOfAll, maxPriceOfAll } = useBudgetChart()
	if (!minPriceOfAll || !maxPriceOfAll) {
		return (
			<Box px={16} py={20} textAlign="center">
				<Spinner />
			</Box>
		)
	}

	return (
		<Box px={10} py={12}>
			<BudgetChart sliderValue={formValue || 0} />
			<Slider
				aria-label="budget-slider-SERP"
				onChange={(value) => {
					onChange(value == 0 ? null : value)
				}}
				min={0}
				max={maxPriceOfAll}
				step={1000}
				value={formValue || 0}
				focusThumbOnChange={false}
			>
				<SliderMark value={0} mt={3} ml="-2.5" fontSize="sm">
					0kr
				</SliderMark>
				<SliderMark value={maxPriceOfAll / 2} mt={3} fontSize="sm">
					{(maxPriceOfAll / 2).toLocaleString('sv-SE')}kr
				</SliderMark>
				<SliderMark value={maxPriceOfAll} mt={3} ml="-2.5" fontSize="sm">
					{maxPriceOfAll.toLocaleString('sv-SE')}kr
				</SliderMark>
				<SliderTrack>
					<SliderFilledTrack />
				</SliderTrack>
				<SliderThumb boxSize={6} borderColor="blue.500" />
			</Slider>
		</Box>
	)
}

interface BudgetFilterPopoverProps extends BudgetSliderProps {
	isOpen: boolean
	onClose: () => void
}

export const BudgetFilterPopover: FC<
	BudgetFilterPopoverProps & PopoverProps & PropsWithChildren
> = ({ isOpen, onClose, formValue, onChange, children, ...rest }) => {
	return (
		<Popover isOpen={isOpen} onClose={onClose} isLazy {...rest}>
			{children}
			<PopoverContent px={5} py={0}>
				<PopoverArrow />
				<PopoverCloseButton aria-label="Close Budget Slider" />
				<BudgetSlider formValue={formValue} onChange={onChange} />
			</PopoverContent>
		</Popover>
	)
}
