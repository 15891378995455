import { useMemo, useState } from 'react'
import type { FC } from 'react'
import { useQuery } from 'react-query'
import { RouteComponentProps, useLocation, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import {
	Container,
	Box,
	Skeleton,
	Flex,
	Text,
	ChakraProps,
	Button,
} from '@chakra-ui/react'
import {
	MapsIcn,
	SearchQuestionmarkIcn,
	NavigationMenuIcn,
} from '@alexanderathoodly/ui-library'
import { CoworkingSolutionSerpCard } from './resultcard'
import {
	getPaginationLinks,
	getPaginatedResult,
	sortCoworkingSerpResult,
	useCoworkingSerpParams,
	usePagination,
	useSortSelector,
} from './utils'
import { FiltersBar, FiltersModalControl, SortField } from './filters'
import { PublicPageLayout, ErrorPage } from '~/layouts/pages'
import { usePersonOfTheDay } from '~/forms/utils'
import { GAAction, sendGA4Event } from '~/analytics/ga'
import { Helmet } from 'react-helmet-async'
import {
	getImgProxyUrl,
	getImgProxyAttributes,
	SIZE_410,
	SIZE_1200,
} from '@yta/image-proxy'
import { Header } from '~/layouts/header/header'
import Map from './map'
import {
	useMetaContent,
	getAreaTextWithPrefix,
	getOfficeType,
} from './textutils'
import { getCoworkingSearchResultQuery } from '~/queries/coworking_search_result'
import { useFAQs } from './faqs'
import { getFaqPage, FaqsSection } from '~/structured_data/faqsection'
import { InfoTable } from './infotable'
import { TopSection } from './topsection'
import {
	ResultBannerWithExpert,
	ResultBannerWithOfficeImage,
	SeoBanner,
} from './banners'
import CityLinks from './arealinks'
import {
	FILTER_TYPE_ALL,
	FILTER_TYPE_MEMBERSHIP,
	FILTER_TYPE_OFFICE,
} from './urlGenerator'
import { useTranslation, Trans } from 'react-i18next'
import { useAlternateLinks } from '~/utils/alternatelinks'
import { useTranslationKey } from '~/utils/translations'
import { areaFeatures, useYtaQuery } from '@alexanderathoodly/data-models'
import PaginationButtons from './paginatebuttons'
import { SeoText } from './seotexts'

const ResultSkeleton: FC = () => {
	return <Skeleton h={60} mb={6} />
}

const NoResultContent: FC = () => {
	const { t } = useTranslation()
	const todaysexpert = usePersonOfTheDay()
	const { areas, area_url } = useCoworkingSerpParams()

	const { city } = useParams<{
		city: string
	}>()
	const search = areas
		.reduce((params, area) => {
			params.append('area', area)
			return params
		}, new URLSearchParams())
		.toString()
	return (
		<Flex direction="column" gap={9}>
			<Box
				display="flex"
				alignItems={{ base: 'flex-start', md: 'center' }}
				gridGap={3}
			>
				<SearchQuestionmarkIcn h={6} w={8} />
				<Text>
					<Trans i18nKey="Din sökning gav tyvärr inget resultat. Prova att <1>rensa filter</1> för att göra en mer omfattande sökning.">
						Din sökning gav tyvärr inget resultat. Prova att{' '}
						<Box
							as={Link}
							color="blue.500"
							to={{
								pathname: `${t('/lediga-kontorshotell', { ns: 'url' })}${
									area_url ? `/${area_url}` : ''
								}`,
								search,
							}}
						>
							rensa filter
						</Box>{' '}
						för att göra en mer omfattande sökning.
					</Trans>
				</Text>
			</Box>
			<CityLinks city={city} />
			<ResultBannerWithExpert todaysExpert={todaysexpert} />
		</Flex>
	)
}

function getImgPreloader(sources: string[] | undefined) {
	if (!sources) {
		return <></>
	}
	const linkTags = sources.map((src, index) => {
		const { imgSrc, srcSetParts, sizes } = getImgProxyAttributes(src, {
			base: SIZE_410,
		})
		return (
			<link
				key={src}
				rel="preload"
				fetchPriority={index == 0 ? 'high' : 'auto'}
				as="image"
				href={imgSrc}
				imageSrcSet={srcSetParts.join(', ')}
				imageSizes={sizes.join(', ')}
			/>
		)
	})
	return linkTags
}

const CoworkingSerpLayout: FC<
	RouteComponentProps<{ city: string; neighborhood: string }>
> = ({ match }) => {
	const { t, i18n } = useTranslation()
	const criteria = useCoworkingSerpParams()
	const {
		data: serpData,
		isLoading: serpIsLoading,
		isIdle: serpIsIdle,
		isError: serpIsError,
		error: serpError,
	} = useQuery(
		getCoworkingSearchResultQuery({
			...criteria,
			type:
				criteria.type !== FILTER_TYPE_OFFICE &&
				criteria.type !== FILTER_TYPE_MEMBERSHIP
					? undefined
					: criteria.type,
		})
	)
	const { data: areaFeatureCollection, isLoading: isAreasLoading } =
		useYtaQuery({
			...areaFeatures({
				ids: criteria.areas,
				area_url: match.params.neighborhood
					? `${match.params.city}/${match.params.neighborhood}`
					: match.params.city
					? match.params.city
					: !criteria.areas.length
					? 'stockholm'
					: undefined,
			}),
		})
	const { numberOfPages, currentPage } = usePagination({
		locations: serpData?.locations,
	})
	const [showMap, setMapMode] = useState(false)
	const { pathname } = useLocation()
	const pathnames = pathname.split('/')
	const pageType = i18n.language == 'sv' ? pathnames[1] : pathnames[2]

	const city = isAreasLoading
		? undefined
		: areaFeatureCollection?.features[0]?.properties.address.city ||
		  areaFeatureCollection?.features[0]?.properties.address.municipality ||
		  areaFeatureCollection?.features[0]?.properties.address.county ||
		  ''

	function getNeighborhood() {
		if (isAreasLoading || !areaFeatureCollection?.features) {
			return undefined
		}
		if (areaFeatureCollection?.features.length > 1) {
			return undefined
		}
		if (!criteria.areas.length && !match.params.neighborhood) {
			return undefined
		}
		return areaFeatureCollection?.features[0]?.properties.address.suburb || ''
	}
	const neighborhood = getNeighborhood()
	const FAQs = useFAQs(
		serpData,
		{
			area: getAreaTextWithPrefix(neighborhood, city, false, t),
			areaWithCity: getAreaTextWithPrefix(neighborhood, city, true, t),
		},
		pageType || ''
	)
	const todaysExpert = usePersonOfTheDay()
	const { sortMode } = useSortSelector()

	const rows = useMemo(() => {
		if (serpIsError) {
			return {
				resultRows: [],
				firstImages: undefined,
			}
		}
		if (serpIsIdle || serpIsLoading) {
			return {
				resultRows: [1, 2, 3, 4, 5, 6].map((i) => <ResultSkeleton key={i} />),
				firstImages: undefined,
			}
		}

		const sortedResult = sortCoworkingSerpResult(
			serpData.locations,
			sortMode,
			criteria.workspaces,
			criteria.type || FILTER_TYPE_ALL
		)
		const paginatedResult = getPaginatedResult(
			sortedResult,
			criteria?.page || 1
		)
		const resultRows = paginatedResult.map((result, index) => {
			return (
				<CoworkingSolutionSerpCard
					result={result}
					key={result.id}
					locationIndex={serpData.featured_locations.length + index}
				/>
			)
		})
		for (let i = 2; i < resultRows.length; i += 6) {
			const bannerNumber = Math.floor((i / 3) % 3)
			resultRows.splice(
				i,
				0,
				bannerNumber == 0 ? (
					<ResultBannerWithOfficeImage
						key={`result-banner-${i}`}
						variant="serpRightImage"
					/>
				) : bannerNumber == 1 ? (
					// We add the SEO banner component only /lediga-kontorshotell/stockholm for an experimental purpose.
					(pathname == '/lediga-kontorshotell/stockholm' ||
						pathname == '/en/serviced-office/stockholm') &&
					currentPage == 1 ? (
						<SeoBanner key={`result-banner-${i}`} />
					) : (
						<ResultBannerWithExpert
							key={`result-banner-${i}`}
							todaysExpert={todaysExpert}
						/>
					)
				) : (
					<ResultBannerWithOfficeImage
						key={`result-banner-${i}`}
						variant="serpLeftImage"
					/>
				)
			)
		}
		if (serpData.featured_locations.length) {
			resultRows.unshift(
				...serpData.featured_locations.map((loc, index) => (
					<CoworkingSolutionSerpCard
						result={loc}
						key={`${loc.id}-featured`}
						locationIndex={index}
						isFeaturedListingCard={true}
					/>
				))
			)
		}
		return {
			resultRows,
			// We preload only the first images of first 3 - 5 locations for performance purpose
			firstImages: serpData.featured_locations
				.concat(sortedResult.slice(0, 3))
				.map((location) =>
					location.is_premium_listing
						? [...location.images.slice(0, 3), location.provider_logo]
						: location.images[0]
				)
				.filter((image): image is string => !!image)
				.flat(),
		}
	}, [
		serpData,
		serpIsLoading,
		serpIsIdle,
		serpIsError,
		todaysExpert,
		sortMode,
		criteria.workspaces,
		criteria.type,
		criteria.page,
		currentPage,
		pathname,
	])

	const { metaTitle, metaDescription } = useMetaContent(
		criteria.page,
		pageType,
		serpData,
		neighborhood,
		city
	)

	const serpType = useTranslationKey(pageType, 'url')
	const alternateLinks = useAlternateLinks(
		serpType && `/${serpType}`,
		`${match.params.city ? match.params.city : ''}${
			match.params.neighborhood ? `/${match.params.neighborhood}` : ''
		}`
	)
	const canonicalLinkBase =
		alternateLinks?.find((link) => link.lng == i18n.language)!.url || undefined
	const { canonicalLink, nextLink, previousLink } = getPaginationLinks(
		canonicalLinkBase,
		criteria.page,
		numberOfPages
	)
	if (serpIsError) {
		// Typescript is not smart enough to understand that error will always be set if isError is set so we need casting
		// TODO: maybe use a union as result of useCoworkingSerp to have typescript understand the error type condition
		return <ErrorPage error={serpError} />
	}

	const ToggleLayoutButton: FC<ChakraProps> = ({ ...rest }) => {
		const { t } = useTranslation()

		return (
			<Button
				leftIcon={
					showMap ? (
						<NavigationMenuIcn height={4} width={4} strokeWidth={1.5} />
					) : (
						<MapsIcn height={4} width={4} strokeWidth={1.5} />
					)
				}
				aria-label={showMap ? t('Lista') : t('Karta')}
				variant="outline"
				borderColor="grey.300"
				minW={0}
				onClick={() => {
					GAAction({
						category: 'site_interaction',
						action: 'open_map_view',
					})
					sendGA4Event('site_interaction', { type: 'map' })
					setMapMode((oldVal) => !oldVal)
				}}
				{...rest}
			>
				{showMap ? t('Lista') : t('Karta')}
			</Button>
		)
	}

	const MapLayout = () => {
		return (
			<Box h="100vh" display="grid" gridTemplateRows="5.5rem 7rem 1fr">
				<Header />
				<Container maxW="container.lg" mb={5}>
					{serpIsLoading || serpIsIdle ? (
						<Skeleton h={6} mb={5} />
					) : (
						<TopSection
							page={criteria.page}
							pageType={pageType}
							result={serpData}
							neighborhood={neighborhood}
							city={city}
							mapMode={showMap}
							isLoading={serpIsLoading}
						/>
					)}
					<Flex gap={16}>
						<FiltersBar flex={1} />
						<ToggleLayoutButton />
						<FiltersModalControl />
					</Flex>
				</Container>
				<Map
					results={serpData?.locations || []}
					visible={showMap}
					features={areaFeatureCollection}
				/>
			</Box>
		)
	}

	const ListLayout = () => {
		return (
			<PublicPageLayout>
				<Container maxW="container.lg">
					<Box display="contents" bgColor="white">
						<TopSection
							page={criteria.page}
							pageType={pageType}
							result={serpData}
							neighborhood={neighborhood}
							city={city}
							mapMode={showMap}
							isLoading={serpIsLoading}
						/>
						<Flex
							gap={{ base: 3, md: '10px' }}
							height={20}
							position={{ base: 'sticky', md: 'static' }}
							top={0}
							bg="white"
							alignItems="center"
							zIndex={3}
						>
							<FiltersBar />
							<SortField display={{ base: 'inline-flex', md: 'none' }} />
							<ToggleLayoutButton ml={{ md: 'auto' }} />
							<FiltersModalControl justifyContent="end" />
						</Flex>
					</Box>
					{!rows.resultRows.length && <NoResultContent />}
					<Box>{rows.resultRows}</Box>
					{serpData ? (
						<PaginationButtons locations={serpData.locations} mb={12} />
					) : (
						<Skeleton mb={12} />
					)}
					{rows.resultRows.length > 0 && (
						<>
							{!criteria.page && (
								<InfoTable
									result={serpData}
									geography={{
										area: getAreaTextWithPrefix(neighborhood, city, false, t),
										areaWithCity: getAreaTextWithPrefix(
											neighborhood,
											city,
											true,
											t
										),
									}}
									officeType={t(getOfficeType(pageType || ''), { count: 2 })}
									mt="2rem"
								/>
							)}
							{!criteria.page && <FaqsSection FAQs={FAQs} mt="2rem" />}
							{/**
							 * TODO: Check placement
							 */}
							{!criteria.page &&
								pageType &&
								match.params.city &&
								!match.params.neighborhood && (
									<SeoText pageType={pageType} city={match.params.city} />
								)}
							<CityLinks city={match.params.city} mt={10} />
						</>
					)}
				</Container>
			</PublicPageLayout>
		)
	}

	return (
		<>
			{!(serpIsIdle || serpIsLoading) && metaTitle && metaDescription && (
				<Helmet>
					<title key="title">{metaTitle}</title>
					{!criteria.page && getFaqPage(FAQs)}
					<meta
						key="description"
						name="description"
						content={metaDescription}
					/>
					<meta
						name="robots"
						content={
							criteria.budget ||
							criteria.services.length > 0 ||
							criteria.workspaces ||
							criteria.type ||
							(criteria.page && criteria.page == 1) ||
							serpData.locations.length === 0 ||
							!match.params.city
								? 'noindex, follow'
								: 'index, follow'
						}
					/>
					{serpData.locations.length && serpData.locations[0]?.images.length && (
						<>
							{serpData.locations[0].images[0] && (
								<meta
									property="og:image"
									content={getImgProxyUrl(
										serpData.locations[0].images[0],
										SIZE_1200
									)}
								/>
							)}
							<meta
								property="og:image:width"
								content={SIZE_1200.width.toString()}
							/>
							<meta
								property="og:image:height"
								content={SIZE_1200.height.toString()}
							/>
						</>
					)}
					{getImgPreloader(rows.firstImages)}
					{alternateLinks &&
						alternateLinks.map((link) => (
							<link
								key={link.url}
								rel="alternate"
								hrefLang={link.lng}
								href={link.url}
							/>
						))}
					{alternateLinks && (
						<link
							rel="alternate"
							hrefLang="x-default"
							href={alternateLinks.find((link) => link.lng == 'sv')!.url}
						/>
					)}
					{criteria.areas.length == 0 && canonicalLink && (
						<link rel="canonical" href={canonicalLink} />
					)}
					{nextLink && <link rel="next" href={nextLink} />}
					{previousLink && <link rel="prev" href={previousLink} />}
				</Helmet>
			)}

			{showMap ? MapLayout() : ListLayout()}
		</>
	)
}

export default CoworkingSerpLayout
