export function capitalizeFirstLetter(toBeCapitalized: string): string {
	if (!toBeCapitalized) {
		return ''
	}
	return toBeCapitalized[0]!.toUpperCase() + toBeCapitalized.slice(1)
}

export function truncate(string: string, length: number): string {
	if (string.length > length) {
		return string.slice(0, length) + '...'
	} else return string
}
