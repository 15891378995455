import type { FC } from 'react'
import { Box, Text, Heading, Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import type { TFunction } from 'react-i18next'

type OfficeTypes = 'office' | 'serviced_office' | 'coworking_space'
type MajorCities = 'stockholm' | 'göteborg' | 'malmö'
type SEOText = {
	title: string
	paragraphs: string[]
}

const SEO_TEXTS: {
	[key in MajorCities]: {
		[key in OfficeTypes]: SEOText[]
	}
} = {
	stockholm: {
		office: [
			{
				title: 'Vad innebär flexibla kontor i Stockholm?',
				paragraphs: [
					'Flexibla kontor är den delen av kontorsmarknaden som innebär kontor med flexibla avtal och / eller kontor med inkluderade tjänster. Till skillnad från traditionella kontor, som i regel hyrs på 3 år eller längre och hyrs som tomma ytor, är flexibla kontor en helhetstjänst där du som hyresgäst kan luta dig tillbaka och förlita dig på att en professionell motpart ser till att ert kontor fungerar som det ska. Avtalen är kortare, mellan 3-12 månader, och innebär att ni kan skala kontoret utefter ert behov.',
					'Den flexibla kontorsmarknaden i Stockholm har vuxit kraftigt de senaste åren, och utgör idag över 7% av den totala kontorsmarknaden. Mer eller mindre alla fastighetsägare har lanserat egna produkter inom segmentet, och utöver det finns det nästan 200 olika kontorshotell i Stockholm som erbjuder flexibla kontor.',
					'På yta.se samlar vi hela utbudet av flexibla kontor i Stockholm på en och samma plats, med en realtidsuppdaterad databas av över 10 000 kontor komplett med pris, bilder och villkor. Utöver det erbjuder vi kostnadsfri sökhjälp via våra kontorsexperter som årligen hjälper hundratals företag hitta rätt kontor för deras team, tveka inte att höra av dig så hjälper vi er med allt från att hitta alternativ, boka visningar, och sist men inte minst förhandla fram en bra deal.',
				],
			},
			{
				title: 'Olika typer av kontor i Stockholm – vad finns att välja på?',
				paragraphs: [
					'Det finns många olika produktsegment i marknaden, låt oss gå igenom några av de vanligaste. För mindre team på 1-10 anställda är det vanligaste att hyra ett eller flera privata kontorsrum på ett kontorshotell eller coworking. Det innebär att ni har en privat och låsbar yta där ni har era arbetsplatser, och sedan delar på gemensamma ytor som lounger, mötes- och konferensrum, reception med mera med andra företag. Detta är den vanligaste och mest populära lösningen idag. Du kan räkna med att allt ingår i hyran och det mer eller mindre bara är att ta med sig datorn och flytta in.',
					'Nästa steg upp är att hyra en egen avdelning, även kallad Big Office, på ett kontorshotell. Denna typ av lösning passar för företag mellan 20-100 anställda. Det innebär att ni tar en del av eller ett helt våningsplan för er själva, men i tillägg till er yta så har ni fortfarande access till tusentals ytterligare kvadratmeter utanför dörren. Ert kontor driftas till fullo av kontorshotellet, komplett med möbler, internet, städning med mera. Jämfört med ett eget kontor så räknar man med en något mindre yta, runt 5-7kvm/person, för att kompensera för det ni också har tillgång till. Fördelen med en egen avdelning jämfört med ett eget kontor är enkelheten i att ta ett mindre kontor än antalet anställda ni är, då ni med enkelhet kan hyra större sammanträdesrum eller utnyttja arbetsplatser i loungerna om ni någon dag är fler inne än ni har arbetsplatser för.',
					'Den sista delen av marknaden som är relativt ny är flexibla egna kontor. Detta är traditionella, helt egna kontorsytor som hyrs ut till flexibla villkor och/eller möbler och service. Dessa hyrs som förstahandsavtal direkt av fastighetsägaren. Fördelen med dessa är att det ofta finns större möjlighet att göra mindre anpassningar av ytan utefter era behov, samt att ni kan fokusera helt på er egen företagskultur. Med dessa kontor får ni räkna med från 10kvm/person, då ni inte får några ytterligare ytor utanför det egna kontoret att nyttja.',
				],
			},
			{
				title:
					'Hur ser ekonomin ut i att hyra ett flexibelt kontor i Stockholm?',
				paragraphs: [
					'Att hyra kontor i Stockholm innebär flera finansiella överväganden, inklusive hyresnivåer, driftskostnader och eventuella serviceavgifter. Beroende på läge och kontorstyp kan kostnaderna variera avsevärt. Många företag väljer flexibla hyresavtal för att anpassa sina kontorsutgifter till sina specifika behov, medan andra söker fastare långsiktiga lösningar för ekonomisk stabilitet.',
					'Många företag stirrar sig blinda på kvadratmeterpriser och får därmed svårt att jämföra olika kontorsprodukter. Vi rekommenderar därför att titta på den totala månadskostnaden för kontoret, inklusive allt som krävs för driften så som möbler, internet och städning. Professionella kontorsaktörer når ofta stordriftsfördelar och kan därför köpa in utrustning till och drifta ett kontor billigare än vad det enskilda företaget kan. ',
					'Till skillnad från traditionella kontor är den största finansiella skillnaden minskade utlägg i form av investeringar och säkerheter, som ofta kan bli stora snabbt. För vanliga kontor är det praxis att erlägga en deposition på 3-6 månadshyror, medan med flexibla kontor är det standard med 0-2 månadshyror. Utöver det krävs inga investeringar i teknik, möbler, personal med mera, som snabbt kan bli tiotusentals kronor per anställd.',
					'Den största besparingen finns ofta i att välja ett mindre kontor än antalet anställda ni är, om ni har en varierad beläggning på kontoret som idag nästan är standard. Exempelvis att hyra en egen avdelning på ett kontorshotell med 30 arbetsplatser, men ha access till 60 anställda, istället för att hyra ett eget kontor som alltid ska ha plats för alla. Ett annat sätt att uppnå det är att endast hyra så mycket yta som ni behöver det kommande året, istället för att ta höjd för er eventuella expansionsplaner de nästkommande 3-5 åren när ni hyr ett kontor.',
				],
			},
			{
				title: 'Prisvärda kontor i Stockholm',
				paragraphs: [
					'När du letar efter ett kontor i Stockholm är ofta priset det första du tittar på och en avgörande faktor. Stockholm erbjuder ett brett spann av kontorslösningar, från budget- och prisvänliga alternativ till mer exklusiva och kostsamma. När du tittar på flexkontor handlar det delvis alltid om läge, men även vilken nivå av service och flexibilitet du söker. Självklart är din hyra lägre desto längre avtal du kan tänka dig att teckna samt ju mindre service som ingår, men här gäller det att se upp så att er totalkostnad när ni ska leverera driften själva ändå inte blir högre.',
					'Stockholm är en stor stad med ett brett utbud av kontor i sina olika delområden och närförorter. De billigaste flexibla kontoren i Stockholm ligger framförallt utanför tullarna i områden så som Kista, Sollentuna, Hammarby Sjöstad och Bromma / Sundbyberg. I dessa områden finns det ett brett utbud av full service kontorshotell men även ett växande utbud av enklare egna kontor som går att hyra med flexibla avtalsvillkor. Räkna på priser från 1500kr/person upp till 4500kr/person och månad.',
					'Inom tull gäller det att satsa på en enklare nivå av service för att komma ner i kostnad, det är nästan viktigare än den specifika stadsdel ni väljer. Kontorshotell med hög andel uthyrningsbar yta kan ofta erbjuda större kontorslösningar till ett lägre pris, och det finns idag även ett växande utbud av egna kontor med flexibla villkor som går att få till en attraktiv peng.',
				],
			},
			{
				title: 'Stockholms Dyraste kontor',
				paragraphs: [
					'För företag som söker premiumlokaler med det lilla extra, erbjuder Stockholm kontor i toppskiktet. Områden som Östermalm, Norrmalm och City är hem för exklusiva flexibla kontor där stil och funktion möts i enastående miljöer, perfekt för företag som söker lyxiga lokaler på representativa adresser men inte vill binda upp sig på 3-5 års avtal. Som tidigare nämnt avgörs priset delvis på vilket läge du är ute efter, men även vilken nivå av service du vill ha.',
					'Om miljön och att skapa en trivsam arbetsplats för kollegor och besökare är högsta prioritet finns det idag många alternativ som verkligen sticker ut från mängden. Några exempel vi tycker om är Cecil Coworking på Norrlandsgatan som upplevs mer som ett 5-stjärnigt hotell, A-House som alltid tar tillvara på fastighetens själ för att skapa några av Stockholms mest vackra kontorsmiljöer, samt Convendum som satt standarden för representativa flexibla kontor speciellt anpassade för större organisationer från start. Det gemensamma med alla alternativ är att de skapat en unik miljö som passar en specifik målgrupp, och där gäller det att utvärdera själv vilken plats just ni passar in på.',
				],
			},
			{
				title: 'Kontorsmarknaden i Stockholm - trender och utveckling',
				paragraphs: [
					'Kontorsmarknaden i Stockholm är dynamisk och fortsätter att utvecklas i takt med nya trender. Ser man till större kontorsmarknader som i USA eller Storbritannien är det tydligt att en av de starkast växande trenderna just nu är egna kontor men med flexibilitet i avtal och service som tillval. Ser man till Stockholm så har vakansgraderna de senaste åren gått upp kraftigt samtidigt som flexibla kontor tagit allt mer mark. Idag står den flexibla delen av kontorsmarknaden i Stockholm för omkring 7-10% av omsättningen, och prognostiseras att växa till 30% till 2030.',
					'Idag finns över 400 000kvm flexibla kontor inom kontorshotell i Stockholm och cirka 40 000 kvm flexibla egna kontor, med över 150 olika aktörer som erbjuder sina tjänster. Utöver kontorshotellen själva har många fastighetsägare gett sig direkt in i matchen så som Vasakronan med sin coworking Arena, Atrium Ljungberg med A-House, Castellum med United Spaces, Fabege med CoW, Revelop med C/O och många fler. Marknaden utvecklas konstant och vi ser att det för var dag kommer nya koncept som lanseras för en allt bredare målgrupp.',
				],
			},
			{
				title: 'Hållbara kontorslösningar i Stockholm',
				paragraphs: [
					'Hållbarhet är en viktig aspekt när det kommer till moderna kontorslösningar. Stockholm leder utvecklingen med gröna byggnader, energieffektiva kontor och fokus på miljövänliga arbetsmiljöer. Flexibla kontor tar hållbarhet till nästa nivå genom att fler människor nyttjar en mindre totalmängd kontorsyta samt att livslängden på ytorna ökar från att anpassas inför varje hyresgäst var tredje år till att leva i många fall 7-15 år framåt.',
					'Utöver att flexibla kontor som koncept är en vinnare från start när det kommer till hållbarhet är det många aktörer som håller fanan högt när det kommer till det egna hållbarhetsarbetet. Genom att lägga mycket tid och resurser på återbruk i all möblering, att renovera varsamt, och att styra fastigheternas drift på ett miljövänligt sätt är förutsättningarna för hållbarhet långt högre än vad en enskild hyresgäst kan uppnå i en egen lokal.',
				],
			},
		],
		serviced_office: [
			{
				title: 'Så fungerar kontorshotell i Stockholm - en överblick',
				paragraphs: [
					'Kontorshotell i Stockholm erbjuder flexibla arbetsmiljöer där grundprincipen går ut på att hyra kontoret som en tjänst, istället för att teckna ett hyresavtal för en tom lokal. Avtalen är i regel korta och kontoren redo att flytta in i direkt, det enda du behöver ha med dig är personalen och datorer. Er hyresvärd, kontorshotellsoperatören, är en professionell motpart som enbart jobbar med att skapa kvalitativa och bra arbetsplatser så att kan fokusera på den egna verksamheten.',
					'Jämfört med ett eget kontor får ni utöver ert privata kontor tillgång till kontorshotellets gemensamma ytor, vilket ofta är tusentals kvadratmeter som ställs till ert förfogande. I snitt utgörs ungefär 30-50% av ett kontorshotell utav privata kontorsrum, och resten är lounger, mötes och konferensrum, reception med mera. Det innebär att du får tillgång till fler mötesrum och allmänna ytor än du hade fått plats med i ett eget kontor.',
					'Konceptet har haft en otrolig tillväxt de senaste 10 åren och idag finns det över 200st kontorshotell i Stockholm och det står för nästan 7% av kontorsmarknaden och är idag det mest populära alternativet för företag mellan 1-20 anställda. Företag kan hyra allt från enskilda skrivbord eller loungeplatser i öppna kontorslandskap, till större egna kontorsutrymmen. Det ingår i princip alltid tjänster som internet, städning och posthantering i hyran, men tjänstelistan kan nästan bli hur stor som helst för den som önskar så som frukost varje dag, AW’s och events, ja allt egentligen som rör kontoret. Helt enkelt en smidig lösning för den som vill ha ett välfungerande kontor.',
					'Idag finns det ett brett utbud av kontorshotell som skiljer sig en hel del i vad de erbjuder. Är du ute efter att bygga egen företagskultur och gärna tar så mycket egen yta som möjligt? Välj en aktör som har färre loungeytor och tjänsteutbud, då ni får bättre möjligheter att skräddarsy er egna yta till ett attraktivt pris. Är ni istället ute efter en inspirerande miljö och ser fördelarna med att träffa andra? Då rekommenderar vi att ni tittar på en aktör i premiumsegmentet, då de bygger sin affär på att skapa community och en arbetsplats utöver det vanliga.',
				],
			},
			{
				title: 'Hur går det till att hyra kontorshotell i Stockholm?',
				paragraphs: [
					'Att hyra ett kontorshotell i Stockholm kan vara en ganska enkel process som avklaras på runt en månad, jämfört med de ofta betydligt längre processerna med att hyra ett traditionellt kontor. Genom yta.se får du full tillgång till vår databas av över 10 000 kontorsrum komplett med vakansstatus, pris, och villkor, som gör det enkelt att snabbt jämföra hela utbudet.',
					'Med hjälp av oss kan du antingen söka själv via marknadsplatsen eller ta kontakt med en av våra kontorsexperter för kostnadsfri hjälp genom hela hyresprocessen. Oavsett hur du gör rekommenderar vi att ni börjar med att analysera ert behov, där de viktigaste aspekterna är läge, budget samt hur många arbetsplatser ni har behov utav. Då vi hjälper hundratals bolag att hitta rätt varje år hjälper vi gärna till i detta skede.',
					'När ni väl vet vad ni vill ha är det dags att få fram offerter på kontorslösningar som passar era behov. På yta.se samlar vi alltid konkreta förslag med pris direkt i eran shortlist, så att ni får ett komplett underlag på vilka lösningar på marknaden som passar er. Nu kommer vi till det viktigaste steget - visningar, där vi rekommenderar att man avsätter en halvdag och går ut på en visningsrunda där ni får se 3-5 alternativ samtidigt. Allt detta kan ni sköta smidigt via marknadsplatsen eller er tillsatta kontorsexpert!',
					'Sista steget är att signera ett hyresavtal med den aktör ni tycker om bäst. Hyresavtal på kontorshotell är väldigt simpla, men villkoren sinsemellan olika kontorshotell kan skilja sig en hel del. Då det sällan är tal om några anpassningar när ni hyr in er på ett kontorshotell så är det ofta möjligt att flytta in redan veckan efter att ni signat.',
				],
			},
			{
				title: 'Prisvärda kontorshotell i Stockholm - budgetvänliga lösningar',
				paragraphs: [
					'Stockholm erbjuder en mängd prisvärda kontorshotell som är perfekta för företag i alla storlekar. Det finns idag ett flertal aktörer i Stockholm som aktivt valt att nischa in sig på just prisvärda kontor som respons till en ökande efterfrågan på kostnadseffektiva kontor. Det gemensamma med dessa lösningar är att de alla uppfyller basbehoven ett kontor behöver uppfylla, så som möbler, internet, kaffe, men ser samtidigt till att hålla en kostnadsmedvetenhet i allt för att föra det vidare till kunden. Det som du som hyresgäst får ge upp i förhållande till mer premiumalternativ är lyxiga möbler, ett bredare utbud av tjänster, färre och mindre loungeytor samt mötesrum, och en allmänt lägre servicenivå.',
					'Kontorshotell som produkt i sig lämpar sig också väldigt väl till kostnadsmedvetna företag då de har flexibla hyresvillkor som gör det enkelt att justera allt från storlek till service och läge efter företagets behov och förutsättningar. Några av de mest prisvärda aktörerna inom tull i Stockholm är till exempel Regus och The Works, medan det utanför tull finns bland annat FastOffice och First Office som budgetvänliga alternativ. Prismässigt kan du hyra ett eget rum på ett kontorshotell inom tull i Stockholm för cirka 1000-1500 kronor per person och månad, och utanför tull kan du komma så lågt som 500-1000 kronor per person och månad. Om du endast söker arbetsplatser i öppna coworking-ytor så kan hyresnivåerna ligga under 500 kronor per person och månad, då får du oftast en väldigt avskalad lösning med begränsad access och utanför tull. Ett sista tips för att hitta ett kostnadseffektivt kontorshotell är att hyra ett mindre rum och nyttja medlemskap för de medarbetarna som inte alltid är inne på kontoret. Vill du ha rådgivning kring vilka kontorshotell som passar er budget? Tveka inte att höra av dig till våra kontorsexperter som kostnadsfritt guidar dig genom marknaden.',
				],
			},
			{
				title: 'Dyraste kontorshotell i Stockholm',
				paragraphs: [
					'För företag som söker det bästa erbjuder Stockholm några riktigt lyxiga kontorshotell med allt från concierge-tjänster och baristor till högteknologiska mötesrum och de bästa adresserna i moderna fastigheter. Dessa premiumlösningar återfinns på några av stadens mest eftertraktade adresser och områden, vilket ger både ett prestigefyllt intryck, samt en funktionalitet och servicenivå utöver det vanliga till företag som vill imponera på kunder och anställda. Föreställ dig kontorsvärldens motsvarighet till ett 5-stjärnigt hotell. Enligt vår erfarenhet väljer många mindre företag som vill ha ett representativt kontor just kontorshotell för att de inte har möjlighet att skapa ett lika premiumkontor själva!',
					'Några av Stockholms premium-alternativ av kontorshotell är Cecil, Convendum, No 18 och A-House för att nämna några. Prisnivåerna här brukar ligga mellan 9000-15000 per person och månad. Områdesmässigt brukar de mer påkostade alternativen ligga i City, Norrmalm och Östermalm, men det finns väldigt fina och exklusiva kontorshotell utanför tull också, till exempel Fabeges coworking-koncept COW i Arenastaden i Solna. Vårt team av kontorsexperter har besökt alla Stockholms lyxigare alternativ och berättar gärna mer om vad de har att erbjuda i ett förutsättningslöst möte.',
				],
			},
			{
				title: 'Kontorshotellmarknaden i Stockholm – trender och utveckling',
				paragraphs: [
					'Marknaden för kontorshotell i Stockholm växer stadigt med ungefär 20% per år. Detta är delvis till följd av pandemin och hybridarbete, men faktum är att tillväxten tog fart redan innan pandemin då företag sökte mer flexibla kontorslösningar och såg ett värde i att hyra kontor som en tjänst snarare än en fysisk produkt att administrera under flera års tid. Det följer även en global trend där fler aktörer inom fastighetsbranschen hakat på den flexibla kontorstrenden och idag finns det få delar av fastighetsmarknaden som inte är påverkade av flexibla kontorsprodukter.',
					'I Stockholm har centrala områden som Vasastan, City, Kungsholmen och Södermalm sett en ökning av kontorshotell som lockar såväl startups som väletablerade företag. En tydlig trend de senaste åren är så kallade Big Office kontor, där större etablerade företag med behov av över 50 fasta arbetsplatser hyr en egen avdelning på ett kontorshotell. Då anpassar man layout, möblering och branding utefter företagets önskemål. Kontorshotell har blivit en populär lösning för företag som vill ha ett prestigefyllt kontor men utan de långsiktiga förpliktelserna som kommer med ett traditionellt hyresavtal. På yta.se följer vi kontorshotellsmarknaden noga med vår marknadsunika data. I Stockholm har utbudstillväxten mattats av det senaste året i takt med att mindre kontorshotell stängt ner, samtidigt som de stora aktörerna fortsätter att växa. Utöver det är det fler och fler fastighetsägare som lanserar sina egna produkter, så som Fabege med CoW, Vasakronan med Arena, Atrium Ljungberg med A-House, samt Castellum med United Spaces.',
				],
			},
			{
				title: 'Hur fungerar det med mötesrum på kontorshotell i Stockholm?',
				paragraphs: [
					'Kontorshotell i Stockholm erbjuder mötesrum i olika storlekar och med modern teknik för att möta företagets behov. Generellt sett så finns det ett antal mötesrum på varje våningsplan som bokas online av hyresgästerna. De flesta mötesrummen är utrustade med videokonferenssystem, whiteboards och andra hjälpmedel som gör det enkelt att hålla professionella möten.',
					'Det skiljer sig mellan olika kontorshotell hur mötesrum prissätts. Generellt är telefonbås och mindre samtalsrum nästan alltid kostnadsfria, medan mötes och konferensrum för 4-6 personer och uppåt kostar att boka. Vissa kontorshotell erbjuder en pott av timmar att utnyttja per månad, medan andra till och med tillhandahåller mötesrummen helt kostnadsfritt. Det går nästan alltid att köpa till paket av timmar för att få en förutsägbar kostnad, och vi ser att detta är en punkt som ofta går att förhandla kring. Se till att ha det i åtanke då det kan spela in på er totala hyreskalkyl om ni är mötesintensiva.',
					'Vissa mer nischade kontorshotell har poddutrustning och musikstudios till exempel, men generellt så är alla utrustade med tv och videoteknik för effektiva digitala möten. För större kontorslösningar, så kallade Big Office, lägger man ofta till interna mötesrum som bara nyttjas av företaget som hyr det kontoret.',
				],
			},
		],
		coworking_space: [],
	},
	göteborg: {
		office: [],
		serviced_office: [],
		coworking_space: [],
	},
	malmö: {
		office: [],
		serviced_office: [],
		coworking_space: [],
	},
}

function getIsMajorCity(city: string | undefined): city is MajorCities {
	if (!city) {
		return false
	}
	return ['stockholm', 'göteborg', 'malmö'].includes(city)
}

function getOfficeType(pageType: string, t: TFunction): OfficeTypes | null {
	if (pageType === t('lediga-kontor', { ns: 'url' })) {
		return 'office'
	}
	if (pageType === t('lediga-kontorshotell', { ns: 'url' })) {
		return 'serviced_office'
	}
	if (pageType === t('coworking-spaces', { ns: 'url' })) {
		return 'coworking_space'
	}
	return null
}

export const SeoText: FC<{ pageType: string; city: string | undefined }> = ({
	pageType,
	city,
}) => {
	const { t } = useTranslation()
	const officeType = getOfficeType(pageType, t)
	const isMajorCity = getIsMajorCity(city)

	if (!isMajorCity || !officeType) {
		return null
	}

	const texts = SEO_TEXTS[city][officeType]

	if (!texts.length) {
		return null
	}
	return (
		<Flex direction="column" gap={3} my={7}>
			{texts.map((text, i) => (
				<Box key={`text-${i}`}>
					<Heading as="h2" fontSize="2xl" fontWeight="semibold" mb={3}>
						{t(text.title)}
					</Heading>
					{text.paragraphs.map((p, i) => (
						<Text key={`p-${i}`} color="grey.500" mb={3}>
							{t(p)}
						</Text>
					))}
				</Box>
			))}
		</Flex>
	)
}
