import { FC, Fragment } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
	Box,
	ChakraProps,
	Flex,
	Image,
	Text,
	useDisclosure,
	Button,
} from '@chakra-ui/react'
import {
	Banner,
	BannerAvatar,
	BannerHeading,
	BannerSubHeading,
	BannerBackgroundCircle,
	BannerImageText,
	BannerSimpleImage,
	BannerButton,
	BannerImageTextWithStarIcon,
	SeoBanner as SeoBannerWrapper,
	SeoBannerAvatar,
	SeoBannerButton,
	SeoBannerFooter,
	SeoBannerMainSection,
	SeoBannerImageText,
	SeoBannerMainText,
} from '@alexanderathoodly/ui-library'
import { Expert, usePersonOfTheDay } from '~/forms/utils'
import { GAAction, sendGA4Event } from '~/analytics/ga'
import OneToTenWsImg from '#/contentpage/1to10ws_for_banner.png'
import FifteenPlusWsImg from '#/contentpage/15plusws_for_banner.png'
import { COWORKING_TYPE, SERVICED_OFFICE_TYPE } from './urlGenerator'
import { useTranslation } from 'react-i18next'
import { useSerpPageTypeInSwedish } from './utils'
import EssveeImg from '#/logos/essve.png'
import HumbleGroupImg from '#/logos/humblegroup.png'
import JuniImg from '#/logos/juni.png'
import LissieImg from '#/logos/lassie.png'

const useTo = (): string => {
	const { t } = useTranslation()
	const { pathname } = useLocation()
	const page = pathname.split('/')[1]
	if (page === COWORKING_TYPE) {
		return t('/coworkinghjalpen', { ns: 'url' })
	}
	if (page === SERVICED_OFFICE_TYPE) {
		return t('/kontorshotellshjalpen', { ns: 'url' })
	}
	return t('/kontorshjalpen', { ns: 'url' })
}

export const ResultBannerWithExpert: FC<{
	todaysExpert: Expert
}> = ({ todaysExpert }) => {
	const { t } = useTranslation()
	const pageType = useSerpPageTypeInSwedish()
	return (
		<Box as={Link} to={useTo()}>
			<Banner
				variant="serpExpert"
				size="xl"
				h={{ base: 'auto', md: '215px' }}
				mb={5}
				py={0}
				px={0}
				pl={{ base: 5, md: 10 }}
				onClick={() => {
					GAAction({
						category: 'engagement',
						action: 'click_expert_banner',
						label: 'expert',
					})
					sendGA4Event('click_kontorshjalpen', {
						page_type: 'serp',
						placement: 'content',
						placement_type: 'banner',
						content: 'expert',
					})
				}}
			>
				<BannerAvatar>
					<Image
						src={todaysExpert.img}
						borderRadius="full"
						w={{ base: '80px', md: '100px' }}
						h={{ base: '80px', md: '100px' }}
						alt={todaysExpert.name}
					/>
				</BannerAvatar>
				<BannerBackgroundCircle />
				<BannerImageText mt={2}>
					{todaysExpert.name}, {t('Kontorsexpert')}
				</BannerImageText>
				<BannerHeading>
					{t(`Jämför hela flexibla kontorsmarknaden - ${pageType}`)}
				</BannerHeading>
				<BannerSubHeading>
					{t(
						`Vårt team av experter tar fram förslag som passar ert team - kostnadsfritt - ${pageType}`
					)}
				</BannerSubHeading>
				<BannerButton>{t('Bli matchad')}</BannerButton>
			</Banner>
		</Box>
	)
}

const BANNER_TEXT = {
	serpRightImage: {
		imgSrc: OneToTenWsImg,
		imgAlt: 'kontor-för-en-till-tio',
		imgW: { base: '100px', md: '110px', lg: '120px' },
		imgH: { base: '100px', md: '110px', lg: '120px' },
		imgText: 'Verifierade kontorsförslag med pris och villkor',
		imgTextM: { ml: 'auto', mr: 5 },
		heading: 'Få skräddarsydda förslag för ert team',
		subHeading:
			'Fyll i era behov och få en shortlist skräddarsydd efter era behov - kostnadsfritt',
		pl: { base: 5, md: 10 },
		pr: 0,
		GALabel: '1_to_10',
	},
	serpLeftImage: {
		imgSrc: FifteenPlusWsImg,
		imgAlt: 'kontor-för-femton-plus',
		imgW: { base: '90px', md: '130px', lg: '190px' },
		imgH: { base: '55px', md: '80px', lg: '120px' },
		imgText: 'Väx med en egen avdelning skapad för större team',
		imgTextM: { ml: 5, mr: 'auto' },
		heading: 'Är ni ett större team? Låt oss hjälpa er',
		subHeading:
			'Vi tar kostnadsfritt fram förslag på egna avdelningar med flexibla avtalsvillkor',
		pl: 0,
		pr: { base: 5, md: 10 },
		GALabel: 'large_team',
	},
}

interface ResultBannerWithOfficeImageProps {
	variant: 'serpLeftImage' | 'serpRightImage'
}

export const ResultBannerWithOfficeImage: FC<
	ResultBannerWithOfficeImageProps
> = ({ variant }) => {
	const { t } = useTranslation()
	const pageType = useSerpPageTypeInSwedish()
	const {
		imgSrc,
		imgAlt,
		imgW,
		imgH,
		imgText,
		imgTextM,
		heading,
		subHeading,
		pl,
		pr,
		GALabel,
	} = BANNER_TEXT[variant]
	return (
		<Box as={Link} to={useTo()} px={0}>
			<Banner
				variant={variant}
				maxH="215px"
				height={{ base: 'min-content', md: '215px' }}
				size="xl"
				mb={5}
				py={0}
				px={0}
				pl={pl}
				pr={pr}
				onClick={() => {
					GAAction({
						category: 'engagement',
						action: 'click_expert_banner', // keep this action to use the same grouping for the all banners
						label: GALabel,
					})
					sendGA4Event('click_kontorshjalpen', {
						page_type: 'serp',
						placement: 'content',
						placement_type: 'banner',
						content: GALabel,
					})
				}}
			>
				<BannerSimpleImage>
					<Image src={imgSrc} w={imgW} h={imgH} alt={imgAlt} />
				</BannerSimpleImage>
				<BannerBackgroundCircle />
				<BannerImageTextWithStarIcon mt={6} ml={imgTextM.ml} mr={imgTextM.mr}>
					{t(`${imgText} - ${pageType}`)}
				</BannerImageTextWithStarIcon>
				<BannerHeading>{t(`${heading} - ${pageType}`)}</BannerHeading>
				<BannerSubHeading>{t(`${subHeading} - ${pageType}`)}</BannerSubHeading>
				<BannerButton>{t('Bli matchad')}</BannerButton>
			</Banner>
		</Box>
	)
}

const QAS_IN_SEO_BANNER = [
	{
		question: 'Vilket typ av kontorshotell passa er verksamhet',
		answer:
			'Det varierade utbudet av kontorshotell i Stockholm medför att du behöver tänka till kring vilket typ av kontorshotell som passar er. Bland premiumalternativen, som oftast finns i och omkring Stockholm City, ingår mycket förutom bara kontorsrum så som frukost, event och rymliga loungeytor. Väljer ni ett mer kostnadseffektivt kontorshotell i Stockholm går ofta kostnaden per kvadratmeter privat yta ner men du får istället räkna med att få plats med mer inne hos er. ',
	},
	{
		question: 'Vad kostar det med kontorshotell i Stockholm?',
		answer:
			'Den första frågan jag brukar fråga är hur mycket kontorsyta ni behöver. Är ni färre än 10 personer på kontoret räcker ofta 1-2st privata kontorsrum, och man brukar räkna på 3-5m² per person. Är ni fler än så är det bäst att börja titta på kombinationer av rum och egna avdelningar och ytbehovet kan bli något större. I Stockholm varierar priserna enormt mellan olika stadsdelar men räkna med mellan 500-1500kr/m²/mån utanför tullarna och mellan 1000 - 3000kr/m²/mån inne i Stockholm City. Det som påverkar priset allra mest är vilken operatör ni väljer och vad som ingår i hyran!',
	},
	{
		question: 'Hur ser marknaden ut för kontorshotell i Stockholm?',
		answer:
			'Stockholms kontorshotellsmarknad har vuxit med 20% per år de senaste åren, och idag finns det över 200 alternativ på marknaden som erbjuds från större kedjor, nischade aktörer, fastighetsägare med mera. Flest kontorshotell finns i Stockholm City, som står för nästan 30% av det totala utbudet. På senare år har mindre och enklare kontorshotell utanför city vuxit snabbt, i förorter så som Kista, Nacka, Söderort med flera. ',
	},
]
const SeoBannerDesktop: FC<ChakraProps> = ({ ...rest }) => {
	const { t } = useTranslation()
	const todaysExpert = usePersonOfTheDay()
	return (
		<Flex
			display={{ base: 'none', md: 'flex' }}
			gap={5}
			py={6}
			borderTop="1px solid"
			borderTopColor="gray.100"
		>
			<SeoBannerWrapper variant="vertical" w="40%" {...rest}>
				<SeoBannerMainSection>
					<SeoBannerAvatar>
						<Image src={todaysExpert.img} borderRadius="full" width="90px" />
					</SeoBannerAvatar>
					<SeoBannerImageText>
						{t('Tips från experten')} <br /> {todaysExpert.name}
					</SeoBannerImageText>
					<SeoBannerMainText>
						{t(
							'Redo att hitta ditt perfekta kontorshotell i Stockholm? Kontakta våra experter idag för en kostnadsfri genomgång av marknaden med målsättningen att få ut er på en visningsrunda i Stockholm.'
						)}
					</SeoBannerMainText>
					<SeoBannerButton>{t('Bli matchad')}</SeoBannerButton>
				</SeoBannerMainSection>
				<SeoBannerFooter>
					<Text my={2} fontSize="xs">
						{t(
							'yta.se hjälper årligen hundratals företag hitta rätt kontorshotell i Stockholm'
						)}
					</Text>
					<Flex
						my={1}
						justifyContent="space-between"
						alignItems="baseline"
						flexWrap="wrap"
						gap={2}
					>
						<Image h={{ base: 5, md: 8 }} src={HumbleGroupImg} />
						<Image h={{ base: 5, md: 7 }} src={EssveeImg} />
						<Image h={{ base: 5, md: 7 }} src={LissieImg} />
						<Image h={{ base: 4, md: 6 }} src={JuniImg} />
					</Flex>
				</SeoBannerFooter>
			</SeoBannerWrapper>
			<Box w="60%">
				<Text fontSize="2xl" fontWeight="semibold" mb={3}>
					{t('Hitta det perfekta kontorshotellet i Stockholm')}
				</Text>
				<Text color="grey.500" mb={3}>
					{`${t(
						'Att hitta rätt kontorshotell i Stockholm kan vara en utmaning, men med rätt strategi är det enklare än du tror. Stockholm har snabbt blivit Sveriges kontorshotellsnav med hundratals alternativ över alla stadsdelar. Här är vår kontorsexpert {{expert}}s bästa tips när man söker kontor',
						{ expert: todaysExpert.name.split(' ')[0] }
					)}:`}
				</Text>
				{QAS_IN_SEO_BANNER.map(({ question, answer }, index) => (
					<Fragment key={`seo-banner-text-${index}`}>
						<Text mb={1} mt={4}>
							{t(question)}
						</Text>
						<Text color="grey.500">{t(answer)}</Text>
					</Fragment>
				))}
			</Box>
		</Flex>
	)
}

const SeoBannerMobile: FC<ChakraProps> = ({ ...rest }) => {
	const { t } = useTranslation()
	const todaysExpert = usePersonOfTheDay()
	const { isOpen, onToggle } = useDisclosure()
	return (
		<Flex
			display={{ base: 'flex', md: 'none' }}
			gap={2}
			pt={3}
			direction="column"
			borderTop="1px solid"
			borderTopColor="gray.100"
		>
			<Text fontSize="xl" mb={3}>
				{t('Hitta det perfekta kontorshotellet i Stockholm')}
			</Text>
			<Text color="blackAlpha.600" mb={3}>
				{`${t(
					'Att hitta rätt kontorshotell i Stockholm kan vara en utmaning, men med rätt strategi är det enklare än du tror. Stockholm har snabbt blivit Sveriges kontorshotellsnav med hundratals alternativ över alla stadsdelar. Här är vår kontorsexpert {{expert}}s bästa tips när man söker kontor',
					{ expert: todaysExpert.name.split(' ')[0] }
				)}:`}
			</Text>
			<SeoBannerWrapper variant="horizontal" {...rest}>
				<SeoBannerMainSection>
					<SeoBannerAvatar>
						<Image src={todaysExpert.img} borderRadius="full" width="90px" />
					</SeoBannerAvatar>
					<SeoBannerImageText>
						{t('Tips från experten')} <br /> {todaysExpert.name}
					</SeoBannerImageText>
					<SeoBannerMainText>
						{t(
							'Redo att hitta ditt perfekta kontorshotell i Stockholm? Kontakta våra experter idag för en kostnadsfri genomgång av marknaden med målsättningen att få ut er på en visningsrunda i Stockholm.'
						)}
					</SeoBannerMainText>
					<SeoBannerButton>{t('Bli matchad')}</SeoBannerButton>
				</SeoBannerMainSection>
			</SeoBannerWrapper>
			<Box
				mb={isOpen ? '' : '1rem'}
				maxHeight={isOpen ? '' : '320px'}
				position="relative"
				borderBottom={isOpen ? '' : '1px solid rgb(255, 255, 255)'}
				overflow={isOpen ? '' : 'hidden'}
				transition={isOpen ? '' : 'max-height 0.3s ease-in-out 0s'}
			>
				{QAS_IN_SEO_BANNER.map(({ question, answer }, index) => (
					<Fragment key={`seo-banner-text-${index}`}>
						<Text mb={1}>{t(question)}</Text>
						<Text
							color="grey.500"
							mb={3}
							_after={
								!isOpen && index == QAS_IN_SEO_BANNER.length - 1
									? {
											backgroundImage:
												'linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255))',
											bottom: 0,
											content: '""',
											height: '160px',
											position: 'absolute',
											right: '0px',
											width: '100%',
									  }
									: {}
							}
						>
							{t(answer)}
						</Text>
					</Fragment>
				))}
			</Box>
			<Button
				variant="link"
				color="blue.500"
				textDecoration="none"
				mb={5}
				size="sm"
				width={{
					base: '100%',
					md: 'min-content',
				}}
				onClick={() => {
					onToggle()
				}}
			>
				{isOpen ? t('Visa mindre') : t('Läs mer')}
			</Button>
		</Flex>
	)
}

export const SeoBanner: FC<ChakraProps> = ({ ...rest }) => {
	return (
		<>
			<SeoBannerDesktop {...rest} />
			<SeoBannerMobile {...rest} />
		</>
	)
}
