import { useMemo } from 'react'
import type { CoworkingSearchResult } from '@alexanderathoodly/data-models'
import { getOfficeType } from './textutils'
import { formatNamesArray } from './utils'
import { useTranslation } from 'react-i18next'

export function useFAQs(
	searchResult: CoworkingSearchResult | undefined,
	geography: { area: string | undefined; areaWithCity: string | undefined },
	pageType: string
): { question: string; answer: string; isValid: boolean }[] {
	const { t } = useTranslation()
	return useMemo(() => {
		if (!searchResult || !geography.area || !geography.areaWithCity) {
			return []
		}
		const officeType = t(getOfficeType(pageType), { count: 1 }).toLowerCase()
		const officeTypePluralised = t(getOfficeType(pageType), {
			count: 2,
		}).toLowerCase()
		const mostLocationsOperator = {
			names: searchResult.most_locations_operators,
			count: searchResult.most_locations_count,
		}
		const cheapest = {
			locationName: searchResult.min_price_of_all_provider,
			price: searchResult.min_price_of_all,
		}
		const largeTeamFitsLocations = searchResult.large_team_fit_locations
		const dogLocations = searchResult.locations_accepting_dog
		const breakfastLocations = searchResult.locations_serving_breakfast
		const { area, areaWithCity } = geography
		return [
			{
				question: t(
					'Hur många {{office_type_pluralized}} finns det {{area_with_city}}?',
					{
						office_type_pluralized: officeTypePluralised,
						area_with_city: areaWithCity,
					}
				),
				answer: t(
					'{{locations_length}}st {{office_type_pluralized}} finns {{area}}',
					{
						locations_length: searchResult.locations.length,
						office_type_pluralized: officeTypePluralised,
						area,
					}
				),
				isValid: searchResult.locations.length > 0,
			},
			{
				question: t(
					'Vad kostar ett {{office_type}} i snitt {{area_with_city}}?',
					{
						office_type: officeType,
						area_with_city: areaWithCity,
					}
				),
				answer: `${
					searchResult.average_cost_per_user?.toLocaleString('en-US') || ''
				}${t('kr/person/månad')}`,
				isValid: searchResult.average_cost_per_user > 0,
			},
			{
				question: t(
					'Vilket {{office_type}} är det billigaste {{area_with_city}}?',
					{
						office_type: officeType,
						area_with_city: areaWithCity,
					}
				),
				answer: t(
					'{{location_name}} är billigast {{area}}, och kostar från {{ cheapest_price}} per person och månad.',
					{
						location_name: cheapest?.locationName || '',
						area,
						cheapest_price: cheapest?.price
							? cheapest.price.toLocaleString('en-US')
							: '',
					}
				),
				isValid: cheapest.price != null && cheapest.price > 0,
			},
			{
				question: t(
					'Vilka {{office_type_pluralized}} passar bra för större team?',
					{ office_type_pluralized: officeTypePluralised }
				),
				answer: t(
					'{{locations}} är {{example_with_count}} på {{office_type}} {{area}} som har kontor anpassade för team på 20 personer eller fler.',
					{
						locations: formatNamesArray(largeTeamFitsLocations),
						example_with_count: t('exampleWithCount', {
							count: largeTeamFitsLocations.length,
						}),
						office_type: officeTypePluralised,
						area,
					}
				),
				isValid: largeTeamFitsLocations.length > 0,
			},
			{
				question: t(
					'Vilka {{office_type_pluralized}} {{area_with_city}} tillåter hundar?',
					{
						office_type_pluralized: officeTypePluralised,
						area_with_city: areaWithCity,
					}
				),
				answer: t(
					'{{locations}} är {{example_with_count}} på {{office_type_pluralized}} {{area}} som tillåter hundar.',
					{
						locations: formatNamesArray(dogLocations),
						example_with_count: t('exampleWithCount', {
							count: dogLocations.length,
						}),
						office_type_pluralized: officeTypePluralised,
						area,
					}
				),
				isValid: dogLocations.length > 0,
			},
			{
				question: t(
					'Vilket {{office_type}} har flest anläggningar {{area_with_city}}?',
					{
						office_type: officeType,
						area_with_city: areaWithCity,
					}
				),
				answer: t(
					'{{locations}} är den största {{office_type}}{{s}}operatören {{area}} sett till antal anläggningar, de har {{count}}.',
					{
						locations: formatNamesArray(mostLocationsOperator.names),
						office_type: officeType,
						s: officeType == 'kontorshotell' ? t('s') : '',
						area,
						count: mostLocationsOperator.count,
					}
				),
				isValid: mostLocationsOperator.count > 0,
			},
			{
				question: t('Hur stort bör ett kontorsrum vara?'),
				answer: t(
					'På {{ office_type_pluralized}} rekommenderar vi att man siktar på 3-5kvm per person egen kontorsyta, utöver de gemensamma ytorna.',
					{ office_type_pluralized: officeTypePluralised }
				),
				isValid: true,
			},
			{
				question: t('På vilka {{office_type_pluralized}} ingår frukost?', {
					office_type_pluralized: officeTypePluralised,
				}),
				answer: t(
					'{{locations}} är {{example_with_count}} på {{office_type_pluralized}} {{area}} som erbjuder frukost.',
					{
						locations: formatNamesArray(breakfastLocations),
						example_with_count: t('exampleWithCount', {
							count: breakfastLocations.length,
						}),
						office_type_pluralized: officeTypePluralised,
						area,
					}
				),
				isValid: breakfastLocations.length > 0,
			},
		].filter((faq) => faq.isValid)
	}, [geography, pageType, searchResult, t])
}
