import { useState } from 'react'
import type { FC } from 'react'
import { Box, Text, Flex, Button, Skeleton } from '@chakra-ui/react'
import type { CoworkingSearchResult } from '@alexanderathoodly/data-models'
import { Heading } from '@chakra-ui/react'
import { getAreaTextWithPrefix } from './textutils'
import { getOperatorCount } from './utils'
import { capitalizeFirstLetter } from '~/utils/text'
import { useTranslation } from 'react-i18next'

function useSubheading(
	pageType: string,
	area: string,
	result: CoworkingSearchResult | undefined
): { subHeading: string; subHeadingText: string } {
	const { t } = useTranslation()
	const operatorCount = result ? getOperatorCount(result.locations) : ''

	const averagePricePerPerson =
		result?.average_cost_per_user?.toLocaleString('en-US') || ''

	const largerstLocationName = result?.biggest_location_name || ''
	const largerstLocationSize =
		result?.biggest_location_size.toLocaleString('sv-SE') || ''

	const totalSize = result?.total_size || 0
	const totalSizeString = totalSize.toLocaleString('sv-SE')
	const locationCount = result?.locations.length || 0
	const averageSizeString = Math.floor(
		totalSize / locationCount
	).toLocaleString('sv-SE')

	const getSubHeading = () => {
		if (pageType === t('lediga-kontorshotell', { ns: 'url' })) {
			return `${t('Kontorshotell')} ${area}`
		}
		if (pageType === t('coworking-spaces', { ns: 'url' })) {
			return `Coworking ${area}`
		}
		// /lediga-kontor/
		return t('Fördelen med flexibla kontor')
	}

	const getSubHeadingText = () => {
		if (pageType === t('lediga-kontorshotell', { ns: 'url' })) {
			return t(
				'Totalt finns det över {{total_size_string}}kvm kontorshotellsyta {{area}}. Det största kontorshotellet är {{largest_location_name}}, som är {{largest_location_size}}kvm stort. I genomsnitt är varje kontorshotell {{average_size_string}}kvm. Snittpriset per arbetsplats i rum är {{average_price_per_person}}kr/månad på kontorshotell {{area}}. {{capitalized_area}} är rummen i snitt anpassade för 3kvm per person men testa gärna att kolla på något större kontor än vad de annonseras för en luftigare känsla.',
				{
					total_size_string: totalSizeString,
					area,
					largest_location_name: largerstLocationName,
					largest_location_size: largerstLocationSize,
					average_size_string: averageSizeString,
					average_price_per_person: averagePricePerPerson,
					capitalized_area: capitalizeFirstLetter(area),
				}
			)
		}
		if (pageType === t('coworking-spaces', { ns: 'url' })) {
			return t(
				'Totalt finns det över {{total_size_string}}kvm coworkingyta {{area}}. Den största coworkinganläggningen är {{largest_location_name}}, som är {{largest_location_size}}kvm stort. I genomsnitt är varje coworking-space {{average_size_string}}kvm. Snittpriset per arbetsplats i rum är {{average_price_per_person}}kr/månad på coworking {{area}}. {{capitalized_area}} är rummen i snitt anpassade för 3kvm per person men testa gärna att kolla på något större kontor än vad de annonseras för en luftigare känsla.',
				{
					total_size_string: totalSizeString,
					area,
					largest_location_name: largerstLocationName,
					largest_location_size: largerstLocationSize,
					average_size_string: averageSizeString,
					average_price_per_person: averagePricePerPerson,
					capitalized_area: capitalizeFirstLetter(area),
				}
			)
		}
		// /lediga-kontor/
		return t(
			'{{capitalized_area}} finns det {{operator_count}} olika operatörer av kontorshotell som erbjuder kontor. Marknaden växer kraftigt då allt fler företag efterfrågar kontor med flexibilitet och full service istället för ett traditionellt 3-5 årigt kontorsavtal. Stora kontorslokaler delas upp i mindre delar som hyrs ut till enskilda bolag, som sedan delar på gemensamma ytor så som konferensrum, lounge och kök. För team på upp till 20 personer passar det bäst att hyra ett eller flera kontorsrum, mellan 3-5kvm/person. Är ni fler än 20 personer rekommenderar vi en egen avdelning där ni räknar på mellan 4-7kvm/person.',
			{
				capitalized_area: capitalizeFirstLetter(area),
				operator_count: operatorCount,
			}
		)
	}

	return { subHeading: getSubHeading(), subHeadingText: getSubHeadingText() }
}

const SubHeading: FC<{
	pageType: string
	area: string
	result: CoworkingSearchResult | undefined
}> = ({ pageType, area, result }) => {
	const { subHeading, subHeadingText } = useSubheading(pageType, area, result)
	return (
		<Box>
			<Heading
				as="h2"
				fontSize={{ base: 'md', md: 'lg' }}
				color="grey.800"
				fontWeight="semibold"
				mt={2}
			>
				{subHeading}
			</Heading>
			<Text color="grey.500" mt={1}>
				{subHeadingText}
			</Text>
		</Box>
	)
}

const MarketplaceSubHeading: FC<{ pageType: string }> = ({ pageType }) => {
	const { t } = useTranslation()
	return (
		<Box
			mt={2}
			display={pageType == t('lediga-kontor', { ns: 'url' }) ? 'none' : 'block'}
		>
			<Heading
				as="h2"
				fontSize={{ base: 'md', md: 'lg' }}
				color="grey.800"
				fontWeight="semibold"
			>
				{pageType === t('lediga-kontorshotell', { ns: 'url' })
					? t('Sveriges största marknadsplats för kontorshotell')
					: t('Sveriges största marknadsplats för coworking')}
			</Heading>
			<Text color="grey.500" mt={1}>
				{pageType === t('lediga-kontorshotell', { ns: 'url' })
					? t(
							'Yta.se marknadsplats för kontorshotell gör det enkelt att jämföra kontorshotell genom att göra varje kontorsrum sökbart. Vad kostar hörnrummet mot gatan som rymmer 4 personer? Vad finns det för lösningar tillgängliga just nu för 40 personer? Få ett fullständigt kontorsförslag med pris, villkor och planritning direkt och boka en visning enkelt. Utbudet uppdateras varje dag löpande genom direktkontakt med kontorshotellen.'
					  )
					: t(
							'Yta.se marknadsplats för coworking gör det enkelt att jämföra coworking-spaces genom att göra varje kontorsrum sökbart. Vad kostar hörnrummet mot gatan som rymmer 4 personer? Vad finns det för lösningar tillgängliga just nu för 40 personer? Få ett fullständigt kontorsförslag med pris, villkor och planritning direkt och boka en visning enkelt. Utbudet uppdateras varje dag löpande genom direktkontakt med coworkingoperatörerna.'
					  )}
			</Text>
		</Box>
	)
}

function useHeading(
	pageType: string,
	area: string | undefined,
	areaWithCity: string | undefined,
	result: CoworkingSearchResult | undefined
): { heading: string | undefined; headingText: string | undefined } {
	const { t } = useTranslation()

	if (area == undefined || areaWithCity == undefined) {
		return { heading: undefined, headingText: undefined }
	}

	const solutionCount = result ? result.all_solutions_count : 0
	const operatorCount = result ? getOperatorCount(result.locations) : ''
	const locationCount = result?.locations.length || 0
	const lowestPricePerPerson = result?.min_price_of_all
		? result.min_price_of_all.toLocaleString('en-US')
		: ''

	const noResultsHeading = (type: string) =>
		t('Lediga {{type}} att hyra {{area_with_city}}', {
			type,
			area_with_city: areaWithCity,
		})

	const getHeading = () => {
		if (pageType === t('lediga-kontorshotell', { ns: 'url' })) {
			return solutionCount > 0
				? t(
						'{{solution_count}} lediga rum på kontorshotell att hyra {{area_with_city}}',
						{
							solution_count: solutionCount,
							area_with_city: areaWithCity,
						}
				  )
				: noResultsHeading(t('Kontorshotell').toLocaleLowerCase())
		}
		if (pageType === t('coworking-spaces', { ns: 'url' })) {
			return solutionCount > 0
				? t(
						'{{solution_count}} lediga rum på coworking spaces att hyra {{area_with_city}}',
						{
							solution_count: solutionCount,
							area_with_city: areaWithCity,
						}
				  )
				: noResultsHeading(t('Coworking spaces').toLocaleLowerCase())
		}
		// /lediga-kontor/
		return solutionCount > 0
			? t('{{solution_count}} lediga kontor att hyra {{area_with_city}}', {
					solution_count: solutionCount,
					area_with_city: areaWithCity,
			  })
			: noResultsHeading(t('Kontor').toLocaleLowerCase())
	}

	const getHeadingText = () => {
		if (pageType === t('lediga-kontorshotell', { ns: 'url' })) {
			return t(
				'Hitta och jämför alla lediga kontor på {{location_count}} kontorshotell hos {{operator_count}} olika kontorshotellsoperatörer {{area}}, eller låt våra kontorsexperter göra jobbet åt dig kostnadsfritt. {{capitalized_area}} kostar det från {{lowest_price_per_person}}kr/person/månad för kontorsrum på kontorshotell, med avtalstider från 1-3 månader där all service och kringutrustning ingår. Oavsett storlek kan ni utforska medlemskap för enstaka anställda, eget kontorsrum för mellanstora team, eller skräddarsy en egen avdelning för 20 arbetsplatser eller fler.',
				{
					location_count: locationCount,
					operator_count: operatorCount,
					area,
					capitalized_area: capitalizeFirstLetter(area),
					lowest_price_per_person: lowestPricePerPerson,
				}
			)
		}
		if (pageType === t('coworking-spaces', { ns: 'url' })) {
			return t(
				'Hitta och jämför {{location_count}} coworking spaces hos {{operator_count}} olika coworkingoperatörer {{area}}, eller låt våra kontorsexperter göra jobbet åt dig kostnadsfritt. {{capitalized_area}} kostar det från {{lowest_price_per_person}}kr/person/månad för kontorsrum på coworking, med avtalstider från 1-3 månader där all service och kringutrustning ingår. Oavsett storlek kan ni utforska coworkingmedlemskap för enstaka anställda, eget kontorsrum för mellanstora team, eller skräddarsy en egen avdelning för 20 arbetsplatser eller fler.',
				{
					location_count: locationCount,
					operator_count: operatorCount,
					area,
					capitalized_area: capitalizeFirstLetter(area),
					lowest_price_per_person: lowestPricePerPerson,
				}
			)
		}
		// /lediga-kontor/
		return t(
			'Hitta och jämför alla kontor med flexibla avtal på {{location_count}} kontorshotell {{area}}, eller låt våra kontorsexperter göra jobbet åt dig kostnadsfritt. {{capitalized_area}} kostar det från {{lowest_price_per_person}}kr/person/månad att hyra ett kontor, med avtalstider från 1-3 månader där all service och kringutrustning ingår. Oavsett storlek kan ni utforska medlemskap för enstaka anställda, eget kontorsrum för mellanstora team, eller skräddarsy en egen avdelning för 20 arbetsplatser eller fler.',
			{
				location_count: locationCount,
				area,
				capitalized_area: capitalizeFirstLetter(area),
				lowest_price_per_person: lowestPricePerPerson,
			}
		)
	}

	return { heading: getHeading(), headingText: getHeadingText() }
}

type TopSectionProps = {
	page: number | undefined
	pageType: string | undefined
	result: CoworkingSearchResult | undefined
	neighborhood: string | undefined
	city: string | undefined
	mapMode: boolean
	isLoading: boolean
}

export const TopSection: FC<TopSectionProps> = ({
	page,
	pageType = '',
	result,
	neighborhood,
	city,
	mapMode,
	isLoading,
}) => {
	const { t } = useTranslation()
	const area = getAreaTextWithPrefix(neighborhood, city, false, t)
	const areaWithCity = getAreaTextWithPrefix(neighborhood, city, true, t)

	const [showSubheadings, setShowSubheadings] = useState(false)

	const { heading, headingText } = useHeading(
		pageType,
		area,
		areaWithCity,
		result
	)

	return (
		<>
			<Flex direction="column" mb="10px">
				<Heading
					as="h1"
					fontSize={
						mapMode ? { base: 'lg', md: '2xl' } : { base: 'lg', md: '4xl' }
					}
					color="grey.800"
					fontWeight="semibold"
					mb={mapMode ? 3 : 1}
				>
					<Skeleton
						w={{ base: 6, md: 9, lg: 14 }}
						h={{ base: 3, md: 6, lg: 7 }}
						display={result ? 'none' : 'inline-block'}
					/>{' '}
					{heading}
					<Skeleton
						w={{ base: 9, md: 14, lg: 20 }}
						h={{ base: 3, md: 6, lg: 7 }}
						display={city == undefined ? 'inline-block' : 'none'}
					/>{' '}
				</Heading>
				{!page && (
					<Flex
						gap="10px"
						direction="column"
						display={
							mapMode ||
							(!isLoading && result && result?.all_solutions_count === 0)
								? 'none'
								: 'flex'
						}
					>
						<Text
							color="grey.500"
							mt={1}
							noOfLines={{
								base: showSubheadings ? undefined : 4,
								md: undefined,
							}}
						>
							{headingText}
						</Text>
						<Flex
							direction="column"
							height={showSubheadings ? 'unset' : '0px'}
							overflow="hidden"
						>
							<MarketplaceSubHeading pageType={pageType} />
							{area && (
								<SubHeading pageType={pageType} area={area} result={result} />
							)}
						</Flex>
						<Button
							variant="ghost"
							size="xs"
							maxW="fit-content"
							p={0}
							mb={2}
							onClick={() => setShowSubheadings(!showSubheadings)}
						>
							{showSubheadings ? t('Visa mindre') : t('Läs mer')}
						</Button>
					</Flex>
				)}
			</Flex>
		</>
	)
}
