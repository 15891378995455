import { jsx as a } from "react/jsx-runtime";
const d = window.btoa, l = "https://storage.googleapis.com/yta-storage/placeholder.png";
function g(h, t) {
  const s = h.replace("https://storage.googleapis.com/", "gs://");
  return `${t.server}/unsafe/rs:fill:${t.width}:${t.height}/${d(s)}.jpeg`;
}
const n = "https://img01.yta.se", b = {
  width: 108,
  height: 60
}, f = {
  width: 288,
  height: 160
}, I = {
  width: 405,
  height: 225
}, P = {
  width: 1071,
  height: 595
}, E = {
  width: 1200,
  height: 630
}, m = {
  lg: 62,
  md: 48,
  base: 0
};
function p(h, t) {
  const s = g(h, {
    width: t.base.width,
    height: t.base.height,
    server: n
  }), e = [], o = [];
  for (const [r, c] of Object.entries(m)) {
    const i = t[r];
    if (i) {
      const w = g(h, {
        width: i.width,
        height: i.height,
        server: n
      });
      e.push(`${w} ${i.width}w`), o.push(`(min-width: ${c}em) ${i.width}px`);
    }
  }
  return {
    imgSrc: s,
    srcSetParts: e,
    sizes: o
  };
}
const S = ({
  src: h,
  sizeSet: t,
  ...s
}) => {
  const {
    imgSrc: e,
    srcSetParts: o,
    sizes: r
  } = p(h, t);
  return /* @__PURE__ */ a("img", {
    src: e,
    srcSet: o.join(", "),
    sizes: r.join(", "),
    width: t.base.width,
    height: t.base.height,
    ...s
  });
};
function $(h, t) {
  return g(h, {
    height: t.height,
    width: t.width,
    server: n
  });
}
export {
  S as ImgProxy,
  l as PLACEHOLDER_SRC,
  P as SIZE_1070,
  b as SIZE_110,
  E as SIZE_1200,
  f as SIZE_290,
  I as SIZE_410,
  p as getImgProxyAttributes,
  $ as getImgProxyUrl
};
