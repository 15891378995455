import type { FC } from 'react'
import {
	Heading,
	Box,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionIcon,
	AccordionPanel,
	Text,
} from '@chakra-ui/react'
import type { ChakraProps } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

export interface FAQ {
	'@type': 'Question'
	name: string
	acceptedAnswer: {
		'@type': 'Answer'
		text: string
	}
}
function getFAQObj(question: string, answer: string): FAQ {
	return {
		'@type': 'Question',
		name: question,
		acceptedAnswer: {
			'@type': 'Answer',
			text: answer,
		},
	}
}

export function getFaqPage(
	FAQs: { question: string; answer: string }[]
): JSX.Element {
	const faqPage = FAQs.length
		? JSON.stringify({
				'@context': 'https://schema.org',
				'@type': 'FAQPage',
				mainEntity: FAQs.map(({ question, answer }) =>
					getFAQObj(question, answer)
				),
		  })
		: ''
	return <script type="application/ld+json">{faqPage}</script>
}

export const FaqsSection: FC<
	{
		FAQs: { question: string; answer: string }[]
	} & ChakraProps
> = ({ FAQs, ...rest }) => {
	const { t } = useTranslation()
	return (
		<Box {...rest}>
			<Heading mb="1rem" size={{ base: 'sm', md: 'md' }}>
				{t('Vanliga frågor och svar')}
			</Heading>
			<Accordion allowToggle>
				{FAQs.map((faq) => (
					<AccordionItem key={faq.question}>
						<Heading as="h2">
							<AccordionButton>
								<Box as="span" flex="1" textAlign="left">
									{faq.question}
								</Box>
								<AccordionIcon ml={2} />
							</AccordionButton>
						</Heading>
						<AccordionPanel pb={4}>
							<Text>{faq.answer}</Text>
						</AccordionPanel>
					</AccordionItem>
				))}
			</Accordion>
		</Box>
	)
}
