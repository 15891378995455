import { useTranslation } from 'react-i18next'
import getUrl from './getUrl'

export function useAlternateLinks(
	translationKey: string | undefined,
	restPath?: string
):
	| {
			lng: string
			url: string
	  }[]
	| undefined {
	const { t, i18n } = useTranslation()
	if (!translationKey) {
		return undefined
	}
	const supportedLngs = (i18n.options.supportedLngs as string[]).filter(
		(lng) => lng !== 'cimode'
	)
	const alternateLinks = supportedLngs.map((lng) => {
		const translatedPath = t(translationKey, { ns: 'url', lng })
		return {
			lng,
			url: restPath
				? getUrl(`${translatedPath}/${restPath}`)
				: getUrl(translatedPath),
		}
	})
	return alternateLinks
}
