import type { FC } from 'react'
import {
	Box,
	Heading,
	Link as ChakraLink,
	UnorderedList,
	ListItem,
} from '@chakra-ui/react'
import type { ChakraProps } from '@chakra-ui/react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { getNearbyAreasQuery } from '~/queries/coworking_search_result'

const useOfficeTypeTranslation = (mainPath: string | undefined) => {
	const { t } = useTranslation()
	if (mainPath == t('coworking-spaces', { ns: 'url' })) {
		return t('Coworking Spaces')
	}
	if (mainPath == t('lediga-kontorshotell', { ns: 'url' })) {
		return t('Kontorshotell')
	}
	return t('Kontor')
}

interface CityLinksProps {
	city: string
}

const CityLinks: FC<CityLinksProps & ChakraProps> = ({ city, ...rest }) => {
	const { data: areas, isLoading } = useQuery(getNearbyAreasQuery(city))
	const { t, i18n } = useTranslation()
	const { pathname } = useLocation()
	const pathnames = pathname.split('/')
	const mainPathname =
		i18n.language == 'sv' ? pathnames[1] : `${i18n.language}/${pathnames[2]!}`
	const officeTypeInPath = i18n.language == 'sv' ? pathnames[1] : pathnames[2]!
	const officeType = useOfficeTypeTranslation(officeTypeInPath)
	if (!city || !mainPathname || !areas || !areas.length || isLoading) {
		return <></>
	}
	return (
		<Box {...rest}>
			<Heading mb="1rem" size={{ base: 'sm', md: 'md' }}>
				{t('{{office_type}} i närheten', {
					office_type: officeType,
				})}
			</Heading>
			<UnorderedList display="flex" flexWrap="wrap" gap={3} ml={0}>
				{areas.map((area) => (
					<ListItem
						key={`area-link-${city}-${area.name}`}
						pl={0}
						sx={{ listStyle: 'none' }}
					>
						<ChakraLink as={Link} to={`/${mainPathname}/${area.url}`}>
							{`${officeType} ${area.name}`}
						</ChakraLink>
					</ListItem>
				))}
			</UnorderedList>
		</Box>
	)
}

export default CityLinks
