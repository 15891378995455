import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import type { FC, MouseEventHandler } from 'react'
import { Layer, Map, Marker, NavigationControl, Source } from 'react-map-gl'
import type { MarkerProps } from 'react-map-gl'
import { Link } from 'react-router-dom'
import { Box, Image } from '@chakra-ui/react'
import {
	PinIcn,
	Banner,
	BannerAvatar,
	BannerHeading,
	BannerSubHeading,
} from '@alexanderathoodly/ui-library'
import type {
	CoworkingLocation,
	NominatimFeatureCollection,
} from '@alexanderathoodly/data-models'
import { CoworkingSolutionMapCard } from './resultcard'
import { usePersonOfTheDay } from '~/forms/utils'
import { GAAction, sendGA4Event } from '~/analytics/ga'
import { useTranslation } from 'react-i18next'
import { useMapManager } from '@yta/floorplan'
import { point as turfPoint, points as turfPoints } from '@turf/helpers'
import turfBBoxPolygon from '@turf/bbox-polygon'
import turfBooleanWithin from '@turf/boolean-within'
import turfBBox from '@turf/bbox'

function getBoundsLikeFromPoints(points: number[][]) {
	const featureCollection = turfPoints(points)
	const bounds = turfBBox(featureCollection)
	return {
		west: bounds[0],
		south: bounds[1],
		east: bounds[2],
		north: bounds[3],
	}
}

type MapProps = {
	results: CoworkingLocation[]
	visible: boolean
	features: NominatimFeatureCollection | undefined
}

const InteractiveMap: FC<MapProps> = ({ results, visible, features }) => {
	const { t } = useTranslation()
	const [hasBeenVisible, setHasBeenVisible] = useState(false)
	const todaysExpert = usePersonOfTheDay()
	const boundsLike = useMemo(() => {
		if ((!features || features.features.length == 0) && results.length == 0) {
			return undefined
		}
		const resultPoints = results.map(({ address }) => [
			address.coordinates.lng,
			address.coordinates.lat,
		])

		if (!features) {
			return getBoundsLikeFromPoints(resultPoints)
		}
		if (results.length == 0) {
			const boundsObj = features.features.reduce(
				(obj, feature) => {
					if (feature.bbox) {
						obj.lngs.push(feature.bbox[0], feature.bbox[2])
						obj.lats.push(feature.bbox[1], feature.bbox[3])
					}
					return obj
				},
				{
					lngs: [] as number[],
					lats: [] as number[],
				}
			)
			return {
				west: Math.min(...boundsObj.lngs),
				south: Math.min(...boundsObj.lats),
				east: Math.max(...boundsObj.lngs),
				north: Math.max(...boundsObj.lats),
			}
		}
		const featurePoints = features.features
			.filter((feature) => {
				// exclude polygon which has at least one location
				const currentFeaturePolygon = turfBBoxPolygon(feature.bbox)
				const pointWithinPolygon = resultPoints.find((point) =>
					turfBooleanWithin(turfPoint(point), currentFeaturePolygon)
				)
				return pointWithinPolygon == undefined ? true : false
			})
			.map((feature) => [
				(feature.bbox[0] + feature.bbox[2]) / 2,
				(feature.bbox[1] + feature.bbox[3]) / 2,
			])

		const allPoints = [...resultPoints, ...featurePoints]
		return getBoundsLikeFromPoints(allPoints)
	}, [features, results])
	const MAP_ZOOM = 10
	const [viewport, setViewport] = useState({
		latitude: boundsLike ? (boundsLike.north + boundsLike.south) / 2 : 59.3253,
		longitude: boundsLike ? (boundsLike.west + boundsLike.east) / 2 : 18.0628,
		zoom: MAP_ZOOM,
	})
	const initialViewState = boundsLike
		? {
				bounds: [
					boundsLike.south,
					boundsLike.west,
					boundsLike.north,
					boundsLike.east,
				] as [number, number, number, number],
				...viewport,
		  }
		: viewport
	const { ref, fitBounds } = useMapManager({ defaultViewport: viewport })
	useEffect(() => {
		if (visible) {
			setHasBeenVisible(true)
		}
	}, [visible])
	useEffect(() => {
		selectResult(null)
	}, [results])
	useEffect(() => {
		if (boundsLike) {
			fitBounds(boundsLike, { padding: 50, animate: true })
		}
	}, [boundsLike, fitBounds])

	const [activeResult, selectResult] = useState<CoworkingLocation | null>(null)
	const markers = useMemo(
		() =>
			results.map((r) => (
				<LocationMarker
					key={r.id}
					longitude={r.address.coordinates.lng}
					latitude={r.address.coordinates.lat}
					selected={activeResult === r}
					onClick={() => selectResult(r)}
				/>
			)),
		[results, activeResult]
	)

	const mapCardClickEvent = useCallback(() => {
		selectResult(null)
	}, [selectResult])
	return hasBeenVisible ? (
		<Box position="relative" display={visible ? 'box' : 'none'}>
			{/* the Banner below is for desktop  */}
			<Box as={Link} to={t('/kontorshjalpen', { ns: 'url' })}>
				<Banner
					variant="float"
					display={{ base: 'none', md: 'grid' }}
					position="absolute"
					zIndex={5}
					top={7}
					right={7}
					onClick={() => {
						GAAction({
							category: 'engagement',
							action: 'click_expert_banner',
							label: 'expert',
						})
						sendGA4Event('click_kontorshjalpen', {
							page_type: 'serp',
							placement: 'content',
							placement_type: 'banner',
							content: 'expert',
						})
					}}
				>
					<BannerAvatar>
						<Image
							src={todaysExpert.img}
							borderRadius="full"
							minWidth="100px"
							alt={todaysExpert.name}
						/>
					</BannerAvatar>
					<BannerHeading>
						{t('Jag hjälper dig till rätt kontor. Boka ett möte.')}
					</BannerHeading>
					<BannerSubHeading>
						<strong>{todaysExpert.name}</strong>, {todaysExpert.positionTitle}.
						{t('100% kostnadsfritt')}
					</BannerSubHeading>
				</Banner>
			</Box>
			{/* the Banner below is for mobile  */}
			<Box as={Link} to={t('/kontorshjalpen', { ns: 'url' })}>
				<Banner
					display={{ base: activeResult ? 'none' : 'grid', md: 'none' }}
					variant="horizontal"
					position="absolute"
					bottom={4}
					left={4}
					right={4}
					zIndex={5}
					size="lg"
					onClick={() => {
						GAAction({
							category: 'engagement',
							action: 'click_expert_banner',
							label: 'expert',
						})
						sendGA4Event('click_kontorshjalpen', {
							page_type: 'serp',
							placement: 'content',
							placement_type: 'banner',
							content: 'expert',
						})
					}}
				>
					<BannerAvatar>
						<Image
							src={todaysExpert.img}
							borderRadius="full"
							minWidth="80px"
							alt={todaysExpert.name}
						/>
					</BannerAvatar>
					<BannerHeading>
						{t('Jag hjälper dig till rätt kontor. Boka ett möte.')}
					</BannerHeading>
					<BannerSubHeading>
						<strong>{todaysExpert.name}</strong>, {todaysExpert.positionTitle}.
						{t('100% kostnadsfritt')}
					</BannerSubHeading>
				</Banner>
			</Box>
			<Map
				{...viewport}
				ref={ref}
				initialViewState={initialViewState}
				onMove={(event) => setViewport(event.viewState)}
				style={{
					width: '100%',
					height: '100%',
					visibility: visible ? 'visible' : 'hidden',
				}}
				mapStyle="mapbox://styles/mikaelg/ckzy6rqu9000b15ps9cv69kac"
				mapboxAccessToken={import.meta.env.VITE_MAPBOX_TOKEN}
			>
				{markers}
				<NavigationControl position="top-left" showCompass={false} />
				{features && (
					<Source id="filtered-areas" type="geojson" data={features}>
						<Layer
							id="serp-filter-features-fill"
							type="fill"
							source="filtered-areas"
							paint={{ 'fill-color': '#CEE6FF', 'fill-opacity': 0.2 }}
						/>
						<Layer
							id="serp-filter-features-line"
							type="line"
							source="filtered-areas"
							paint={{
								'line-color': '#007aff',
								'line-width': 2,
								'line-dasharray': [2, 1],
							}}
						/>
					</Source>
				)}
			</Map>
			{activeResult && (
				<CoworkingSolutionMapCard
					result={activeResult}
					onClickEvent={mapCardClickEvent}
				/>
			)}
		</Box>
	) : null
}

type LocationMarkerProps = MarkerProps & {
	onClick?: MouseEventHandler
	selected: boolean
}

const LocationMarker = memo<LocationMarkerProps>(
	({ onClick, selected, ...props }) => {
		const defaultPinStyle = {
			outer: {
				fill: 'blue.500',
				stroke: 'white',
			},
			inner: { fill: 'white' },
		}
		const selectedPinStyle = {
			outer: {
				fill: 'white',
				stroke: 'blue.100',
			},
			inner: { fill: 'blue.500' },
		}
		return (
			<Marker {...props}>
				<Box as="button" onClick={onClick}>
					<PinIcn
						height={10}
						width={7}
						_hover={selectedPinStyle}
						_selected={selectedPinStyle}
						aria-selected={selected}
						outer={defaultPinStyle.outer}
						inner={defaultPinStyle.inner}
					/>
				</Box>
			</Marker>
		)
	}
)

LocationMarker.displayName = 'LocationMarker'

export default InteractiveMap
