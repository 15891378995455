import { useRef as P, useCallback as a, useEffect as C, useId as y, useState as L } from "react";
import { useMap as _, Layer as w } from "react-map-gl";
import { jsxs as x, Fragment as O, jsx as h } from "react/jsx-runtime";
function H(e, t, n) {
  return e.has(t) ? e.get(t) : (e.set(t, n), n);
}
const k = /* @__PURE__ */ new Map([
  ["fill", "fill-color"],
  ["fillOpacity", "fill-opacity"]
]), F = /* @__PURE__ */ new Map([
  ["stroke", "line-color"],
  ["strokeOpacity", "line-opacity"],
  ["strokeWidth", "line-width"]
]), S = /* @__PURE__ */ new Set([
  ...k.keys(),
  ...F.keys()
]);
function v(e) {
  if (typeof e == "object" && "default" in e) {
    const t = ["case"];
    for (const [n, u] of Object.entries(e))
      n !== "default" && (t.push(["==", ["get", "type"], n]), t.push(u));
    return t.push(e.default), t;
  }
  return e;
}
function M(e, t) {
  return t ? [
    "case",
    ["boolean", ["feature-state", "hover"], !1],
    t,
    e
  ] : e;
}
function I(e, t) {
  if (!t || t.length === 0)
    return e;
  const n = ["case"];
  for (const { predicate: u, value: i } of t)
    n.push(u), n.push(i);
  return n.push(e), n;
}
function $(e) {
  const { _conditions: t } = e, n = /* @__PURE__ */ new Map();
  for (const o of t || [])
    for (const r of S) {
      const s = v(o[r]), c = v(
        o._hover && o._hover[r]
      );
      if (s)
        H(
          n,
          r,
          []
        ).push({
          predicate: o.predicate,
          value: M(s, c)
        });
      else if (c)
        throw new Error(
          `Cannot add a hover style for ${r} without a default value`
        );
    }
  const u = {}, i = {};
  for (const o of S) {
    const r = v(e[o]), s = v(
      e._hover && e._hover[o]
    ), c = n.get(o);
    if (r) {
      const d = M(
        r,
        s
      ), f = I(d, c), p = k.get(o), l = F.get(o);
      p ? u[p] = f : l && (i[l] = f);
    } else if (c)
      throw new Error(
        `Cannot add a conditional style for ${o} without a default value`
      );
  }
  return {
    fillPaint: u,
    linePaint: i
  };
}
function V({
  id: e,
  source: t,
  sourceLayer: n
}) {
  return {
    id: e,
    source: t,
    sourceLayer: n
  };
}
function B(e) {
  const t = _(), n = P([]), u = a(
    (o) => {
      if (!t.current)
        return;
      const s = t.current.queryRenderedFeatures(o.point, {
        layers: [e]
      }).map(V);
      for (const c of n.current)
        t.current.setFeatureState(c, { hover: !1 });
      for (const c of s)
        t.current.setFeatureState(c, { hover: !0 });
      n.current = s;
    },
    [t, e]
  ), i = a(() => {
    if (!!t.current) {
      for (const o of n.current)
        t.current.setFeatureState(o, { hover: !1 });
      n.current = [];
    }
  }, [t]);
  C(() => {
    if (!t.current)
      return;
    const o = t.current;
    return o.on("mousemove", e, u), o.on("mouseleave", e, i), () => {
      o.off("mousemove", e, u), o.off("mouseleave", e, i);
    };
  }, [t, e, u, i]);
}
function A(e, t) {
  const n = _(), u = a(
    (r) => {
      if (!n.current || !t)
        return;
      const s = n.current.queryRenderedFeatures(r.point, {
        layers: [e]
      });
      s.length !== 0 && t(s, r);
    },
    [n, t, e]
  ), i = a(() => {
    n.current && (n.current.getCanvas().style.cursor = "pointer");
  }, [n]), o = a(() => {
    n.current && (n.current.getCanvas().style.cursor = "");
  }, [n]);
  C(() => {
    if (!n.current)
      return;
    const r = !!t, s = n.current;
    return s.on("click", e, u), r && (s.on("mouseenter", e, i), s.on("mouseleave", e, o)), () => {
      s.off("click", e, u), r && (s.off("mouseenter", e, i), s.off("mouseleave", e, o));
    };
  }, [
    n,
    u,
    e,
    t,
    i,
    o
  ]);
}
const T = ({
  id: e,
  source: t,
  types: n,
  onClick: u,
  visibility: i,
  ...o
}) => {
  const {
    fillPaint: r,
    linePaint: s
  } = $(o), c = n ? ["in", ["get", "type"], ["literal", n]] : ["all"], d = y(), f = e ? `${e}-fill` : d, p = y();
  A(f, u), B(f);
  const l = {};
  i && (l.visibility = i);
  const g = {
    ...l
  }, m = {
    ...l
  };
  return n && (g["fill-sort-key"] = ["index-of", ["get", "type"], ["literal", n]], m["line-sort-key"] = ["index-of", ["get", "type"], ["literal", n]]), /* @__PURE__ */ x(O, {
    children: [/* @__PURE__ */ h(w, {
      id: f,
      source: t,
      "source-layer": "default",
      filter: c,
      type: "fill",
      paint: r,
      layout: g
    }), /* @__PURE__ */ h(w, {
      id: e ? `${e}-line` : p,
      source: t,
      "source-layer": "default",
      filter: c,
      type: "line",
      paint: s,
      layout: m
    })]
  });
}, G = ({
  color: e,
  kind: t,
  opacity: n,
  ...u
}) => {
  const i = t == "fill" ? `${e}${n || "FF"}` : t == "line" ? "#fff" : t == "stripes" ? `repeating-linear-gradient(
  -45deg,
  rgba(0,0,0,0),
  rgba(0,0,0,0) 10px,
  ${e} 10px,
  ${e} 13px
)` : e;
  return /* @__PURE__ */ h("div", {
    style: {
      background: i,
      minHeight: 15,
      ...u
    }
  });
}, b = (e) => {
  const t = P(null), [n, u] = L(e.defaultViewport), i = a((r) => {
    u(r.viewState);
  }, []), o = a(
    (r, s) => {
      if (!t.current)
        return;
      const c = [
        [r.west, r.south],
        [r.east, r.north]
      ];
      t.current.fitBounds(c, { animate: !1, ...s });
    },
    []
  );
  return {
    viewport: n,
    handleMove: i,
    fitBounds: o,
    ref: t
  };
};
export {
  T as FloorplanLayer,
  G as LegendColorBox,
  b as useMapManager
};
