import type { CoworkingSearchResult } from '@alexanderathoodly/data-models'
import { useTranslation } from 'react-i18next'
import type { TFunction } from 'react-i18next'
import { usePagination } from './utils'

export function getOfficeType(pageType: string): string {
	if (pageType === 'coworking-spaces') {
		return 'Coworking space'
	}

	if (pageType === 'lediga-kontor') {
		return 'Kontor'
	}

	//lediga-kontorshotell
	return 'Kontorshotell'
}

//Hack to display "i" or "på" for areas correctly
export function getNeighborhoodWithInOnPrefix(
	neighborhood: string,
	t: TFunction
): string {
	//Areas that should be prefixed with "på"
	const onAreas = [
		'södermalm',
		'kungsholmen',
		'lidingö',
		'östermalm',
		'lindholmen',
		'övriga hisingen',
	]
	const prefix =
		onAreas.indexOf(neighborhood.toLowerCase()) >= 0 ? t('på') : t('i')
	return `${prefix} ${neighborhood}`
}

export function getAreaText(
	neighborhood: string | undefined,
	city: string
): string {
	return neighborhood ? `${neighborhood}, ${city}` : `${city}`
}

export function getAreaTextWithPrefix(
	neighborhood: string | undefined,
	city: string | undefined,
	includeCity = true,
	t: TFunction
): string | undefined {
	if (city == undefined) {
		return undefined
	}
	return neighborhood
		? getNeighborhoodWithInOnPrefix(neighborhood, t) +
				`${includeCity && city !== '' ? `, ${city}` : ''}`
		: `${t('i')} ${city}`
}

export function useMetaContent(
	page: number | undefined,
	pageType: string | undefined,
	result: CoworkingSearchResult | undefined,
	neighborhood: string | undefined,
	city: string | undefined
): { metaTitle: string; metaDescription: string } {
	const { t } = useTranslation()
	const { currentPageResultText } = usePagination({
		locations: result?.locations,
	})
	if (city == undefined || !result) {
		return {
			metaTitle: '',
			metaDescription: '',
		}
	}
	const area = getAreaTextWithPrefix(neighborhood, city, false, t)
	const solutionCount = result.all_solutions_count
	const lowestPricePerPerson = result.min_price_of_all
		? result.min_price_of_all.toLocaleString('en-US')
		: ''
	const locationCount = result.locations.length

	const getTitle = () => {
		const translatedPage = t('Sida', { ns: 'meta_data' })
		let mainText = ''
		if (pageType === t('lediga-kontorshotell', { ns: 'url' })) {
			mainText = t(
				'Kontorshotell {{area}} - {{solution_count}} kontor lediga',
				{
					ns: 'meta_data',
					area,
					solution_count: solutionCount,
				}
			)
		} else if (pageType === t('coworking-spaces', { ns: 'url' })) {
			mainText = t(
				'Coworking spaces {{area}} | {{solution_count}} alternativ samlade på yta.se',
				{
					ns: 'meta_data',
					area,
					solution_count: solutionCount,
				}
			)
		} else {
			// /lediga-kontor/
			mainText = t('{{solution_count}} lediga kontor {{area}} att hyra.', {
				ns: 'meta_data',
				area,
				solution_count: solutionCount,
			})
		}
		return page ? `${translatedPage} ${page} | ${mainText}` : mainText
	}

	const getDescription = () => {
		let mainText = ''
		if (pageType === t('lediga-kontorshotell', { ns: 'url' })) {
			mainText = t(
				'Jämför {{location_count}} olika kontorshotell {{area}}, med över {{solution_count}} kontor att välja bland. {{lowest_price_per_person}} Priser på allt ✓ Unikt utbud ✓ Kostnadsfri tjänst ✓',
				{
					ns: 'meta_data',
					location_count: locationCount,
					solution_count: solutionCount,
					area,
					lowest_price_per_person: lowestPricePerPerson
						? t('Från {{lowest_price_per_person}}kr/arbetsplats/månad.', {
								lowest_price_per_person: lowestPricePerPerson,
						  })
						: '',
				}
			)
		} else if (pageType === t('coworking-spaces', { ns: 'url' })) {
			mainText = t(
				'Jämför {{location_count}} coworking spaces {{area}}, med över {{solution_count}} kontor att välja bland. {{lowest_price_per_person}} Priser på allt ✓ Unikt utbud ✓ Kostnadsfri tjänst ✓',
				{
					ns: 'meta_data',
					location_count: locationCount,
					solution_count: solutionCount,
					area,
					lowest_price_per_person: lowestPricePerPerson
						? t('Från {{lowest_price_per_person}}kr/arbetsplats/månad.', {
								lowest_price_per_person: lowestPricePerPerson,
						  })
						: '',
				}
			)
		} else {
			// /lediga-kontor/
			mainText = t(
				'Jämför {{solution_count}} flexibla kontor på {{location_count}} kontorshotell {{area}}. {{lowest_price_per_person}} Priser på allt ✓ Korta hyresavtal ✓ Kostnadsfri tjänst ✓ Full service ✓',
				{
					ns: 'meta_data',
					solution_count: solutionCount,
					location_count: locationCount,
					area,
					lowest_price_per_person: lowestPricePerPerson
						? t('Från {{lowest_price_per_person}}kr/arbetsplats/månad.', {
								lowest_price_per_person: lowestPricePerPerson,
						  })
						: '',
				}
			)
		}
		return page && currentPageResultText
			? `${currentPageResultText} | ${mainText}`
			: mainText
	}

	return {
		metaTitle: getTitle(),
		metaDescription: getDescription(),
	}
}
