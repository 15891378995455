import { lazy } from 'react'
import type { FC } from 'react'
import type { RouteConfig } from '~/utils/routing'
import {
	getLocationByUrlQuery,
	similarLocationsQuery,
} from '~/queries/coworking_location'
import {
	getProviderQuery,
	getTestimonialsQuery,
	servicesQuery,
} from './queries'
import type { RouteComponentProps } from 'react-router-dom'
import type { StatusError } from '@alexanderathoodly/data-models'
import { useQuery, useQueryClient } from 'react-query'
import { getNearbyAreasQuery } from '~/queries/coworking_search_result'

const LocationPageLayout = lazy(() => import('./layout'))
const NotFoundPageLayout = lazy(() => import('../notfound/layout'))

const LocationPage: FC<
	RouteComponentProps<{ url: string; office?: string }>
> = (props) => {
	const queryClient = useQueryClient()
	const location = useQuery(
		getLocationByUrlQuery(props.match.params.url, queryClient)
	)
	if (
		location.status == 'error' &&
		(location.error as StatusError).statusCode == 404
	) {
		return <NotFoundPageLayout />
	}

	return <LocationPageLayout {...props} />
}

const route: RouteConfig<{ url: string; office?: string }> = {
	path: [
		'/kontor/:url/:office',
		'/en/office/:url/:office',
		'/kontor/:url',
		'/en/office/:url',
	],
	exact: false,
	component: LocationPage,
	prefetchQueries: async (match, _, queryClient) => {
		const l = await queryClient.fetchQuery(
			getLocationByUrlQuery(match.params.url, queryClient)
		)
		await Promise.all([
			queryClient.prefetchQuery(getProviderQuery(l.provider)),
			queryClient.prefetchQuery(getTestimonialsQuery(l.id)),
			queryClient.prefetchQuery(servicesQuery),
			queryClient.prefetchQuery(similarLocationsQuery(l.id)),
			queryClient.prefetchQuery(
				getNearbyAreasQuery(l.breadcrumbs.city.name.toLowerCase())
			),
		])
	},
}

export default route
