import type { FC } from 'react'
import { Link } from 'react-router-dom'
import { Flex, Button, Text, IconButton, Skeleton } from '@chakra-ui/react'
import type { ChakraProps } from '@chakra-ui/react'
import type { CoworkingLocation } from '@alexanderathoodly/data-models'
import { RightArrowIcn, ArrowLeftIcn } from '@alexanderathoodly/ui-library'
import { usePagination } from './utils'
import { useTranslation } from 'react-i18next'

const Dots: FC = () => {
	return (
		<Text alignSelf="end" fontSize="2xl" mx={3}>
			...
		</Text>
	)
}

const PaginationButtons: FC<
	{ locations: CoworkingLocation[] } & ChakraProps
> = ({ locations, ...rest }) => {
	const { t } = useTranslation()
	const {
		paginationRange,
		currentPage,
		currentPageResultText,
		isNextPageExist,
		isPreviousPageExist,
		goToNextPage,
		goToPreviousPage,
		getPageLink,
	} = usePagination({
		locations,
	})

	if (paginationRange.length == 0) {
		return (
			<>
				{currentPageResultText ? (
					<Text textAlign="center" fontWeight="semibold">
						{currentPageResultText}
					</Text>
				) : (
					<Skeleton />
				)}
			</>
		)
	}

	return (
		<Flex direction="column" alignContent="center" gap={5} {...rest}>
			{currentPageResultText ? (
				<Text textAlign="center" fontWeight="semibold">
					{currentPageResultText}
				</Text>
			) : (
				<Skeleton />
			)}
			{isNextPageExist && (
				<Button
					variant="solid"
					w={{ base: 'full', md: '50%' }}
					justifySelf="center"
					mx="auto"
					onClick={goToNextPage}
				>
					{t('Nästa')}
				</Button>
			)}
			<Flex justifyContent={{ base: 'space-between', md: 'center' }} gap={3}>
				{isPreviousPageExist && (
					<IconButton
						aria-label="To previous result"
						variant="ghost"
						display={{ base: 'none', md: 'inline-flex' }}
						minW={10}
						icon={<ArrowLeftIcn color="black" h={4} w={4} strokeWidth={4} />}
						onClick={goToPreviousPage}
					/>
				)}
				{paginationRange.map((value, index) => {
					if (value == 0) {
						return <Dots key={`pagination-dots-${index}`} />
					}
					return (
						<Button
							key={`paginate-button-${value}`}
							as={Link}
							to={getPageLink(value)}
							variant={value == currentPage ? 'solid' : 'outline'}
							minW={2}
							onClick={() => window.scrollTo({ top: 0 })}
						>
							{value}
						</Button>
					)
				})}
				{isNextPageExist && (
					<IconButton
						aria-label="To next result"
						variant="ghost"
						display={{ base: 'none', md: 'inline-flex' }}
						minW={10}
						icon={<RightArrowIcn color="black" h={4} strokeWidth={4} />}
						onClick={goToNextPage}
					/>
				)}
			</Flex>
		</Flex>
	)
}

export default PaginationButtons
