import type { FC } from 'react'
import type { CoworkingSearchResult } from '@alexanderathoodly/data-models'
import {
	Box,
	Table,
	Tbody,
	Tr,
	Td,
	TableContainer,
	Heading,
} from '@chakra-ui/react'
import type { ChakraProps } from '@chakra-ui/react'
import { getOperatorCount } from './utils'
import { useTranslation } from 'react-i18next'

export const InfoTable: FC<
	{
		geography: { area: string | undefined; areaWithCity: string | undefined }
		result: CoworkingSearchResult | undefined
		officeType: string
	} & ChakraProps
> = ({ geography, result, officeType, ...rest }) => {
	const { t } = useTranslation()
	const { area, areaWithCity } = geography
	if (!result || area == undefined || areaWithCity == undefined) {
		return <></>
	}
	const data = [
		{
			text: t('Antal kontorshotell {{area}}', { area }),
			value: result.locations.length.toLocaleString('sv-SE'),
			isValid: result.locations.length > 0,
		},
		{
			text: t('Antal operatörer {{area}}', { area }),
			value: getOperatorCount(result.locations),
			isValid: getOperatorCount(result.locations) > 0,
		},
		{
			text: t('Antal lediga kontor {{area}}', { area }),
			value: result.all_solutions_count.toLocaleString('sv-SE'),
			isValid: result.all_solutions_count > 0,
		},
		{
			text: t('Antal kvadratmeter kontorshotell {{area}}', { area }),
			value: result.total_size.toLocaleString('sv-SE'),
			isValid: result.total_size > 0,
		},
		{
			text: t('Billigaste priset'),
			value: `${
				result?.min_price_of_all
					? result?.min_price_of_all.toLocaleString('en-US')
					: ''
			}${t('kr/person/månad')}`,
			isValid: result?.min_price_of_all != null && result?.min_price_of_all > 0,
		},
		{
			text: t('Högsta priset'),
			value: `${
				result?.max_price_of_all
					? result?.max_price_of_all.toLocaleString('en-US')
					: ''
			}${t('kr/person/månad')}`,
			isValid: result?.max_price_of_all != null && result?.max_price_of_all > 0,
		},
		{
			text: t('Populärt alternativ'),
			value: result.locations[0]?.name,
			isValid: result.locations.length > 0,
		},
		{ text: t('Avtalstider från'), value: `3 ${t('månader')}`, isValid: true },
	].filter((d) => d.isValid)

	return (
		<Box {...rest}>
			<Heading mb="1rem" size={{ base: 'sm', md: 'md' }}>
				{t('Snabbfakta om')} {officeType.toLowerCase()} {areaWithCity}
			</Heading>
			<TableContainer>
				<Table size="sm">
					<Tbody>
						{data.map((d, i) => (
							<Tr key={d.text} backgroundColor={i % 2 == 0 ? 'blue.50' : ''}>
								<Td>{d.text}</Td>
								<Td>{d.value}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</TableContainer>
		</Box>
	)
}
