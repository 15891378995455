import { useMemo } from 'react'
import { TEAM } from '../utils/teams'
import type { FlexOfficeResult } from '@alexanderathoodly/data-models'

export type Expert = {
	name: string
	img: string
	positionTitle: string
}

export const usePersonOfTheDay = (): Expert => {
	return useMemo(() => {
		const experts: Expert[] = Object.entries(TEAM).map(([name, expertInfo]) => {
			return {
				name: name,
				img: expertInfo.img,
				positionTitle: expertInfo.positionTitle,
			}
		})
		const now = new Date()
		const todaysDate = now.getUTCDate()
		// The purpose of this condition is to get consistent data with cached html file
		if (now.getUTCHours() >= 7) {
			return experts[todaysDate % experts.length]!
		}
		return experts[(todaysDate - 1) % experts.length]!
	}, [])
}

export function getGAClientId(): Promise<string> {
	return new Promise((resolve) => {
		setTimeout(() => resolve(''), 50)
		ga((tracker) => {
			resolve(tracker && tracker.get('clientId') ? tracker.get('clientId') : '')
		})
	})
}

export function getGA4ClientId(): Promise<string> {
	return new Promise((resolve) => {
		setTimeout(() => resolve(''), 50)
		gtag('get', 'G-0WPNRWN9QC', 'client_id', (client_id) => {
			resolve((client_id as string) || '')
		})
	})
}

export type FlexOfficeResultWithCheapestOffer = FlexOfficeResult & {
	fromPrice?: number | undefined
}
