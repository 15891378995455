import type { FC } from 'react'
import {
	Icon,
	Skeleton,
	Text,
	useDisclosure,
	Flex,
	Box,
	Button,
	useMediaQuery,
} from '@chakra-ui/react'
import { useQuery } from 'react-query'
import {
	DogIcn,
	CoffeeCupHotIcn,
	OfficeChairIcn,
	ParkingIcn,
	BathroomShowerIcn,
	FitnessDumbbellIcn,
	BreakfastCroissantIcn,
	WineGlassIcn,
	RestaurantForkKnifeIcn,
	WarehouseStorageIcn,
	TableRestaurantBeachIcn,
	MicrophonePodcastPersonIcn,
	BedWindowIcn,
	InformationDeskManIcn,
	BicycleSportsIcn,
	PresentationIcn,
	WaitingRoomLampIcn,
} from '@alexanderathoodly/ui-library'
import { servicesQuery } from './queries'
import type { ChakraComponent } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

export const SERVICES: {
	[name: string]: {
		icon: ChakraComponent<FC<unknown>>
		name: string
	}
} = {
	coffee: { icon: CoffeeCupHotIcn, name: 'Kaffe' },
	dog_friendly: { icon: DogIcn, name: 'Hund tillåtet' },
	furnished: { icon: OfficeChairIcn, name: 'Möblerad' },
	parking: { icon: ParkingIcn, name: 'Parkering' },
	shower: { icon: BathroomShowerIcn, name: 'Dusch' },
	gym: { icon: FitnessDumbbellIcn, name: 'Gym' },
	breakfast: { icon: BreakfastCroissantIcn, name: 'Frukost' },
	bar: { icon: WineGlassIcn, name: 'Bar' },
	standup_desk: {
		icon: InformationDeskManIcn,
		name: 'Höj- och sänkbara skrivbord',
	},
	storage_space: { icon: WarehouseStorageIcn, name: 'Lagerplats' },
	terrace: {
		icon: TableRestaurantBeachIcn,
		name: 'Terass/innergård',
	},
	studio: {
		icon: MicrophonePodcastPersonIcn,
		name: 'Inspelningsstudio',
	},
	resting_room: { icon: BedWindowIcn, name: 'Vilorum' },
	bicycle_parking: {
		icon: BicycleSportsIcn,
		name: 'Cykelparkering',
	},
	conference: { icon: PresentationIcn, name: 'Konferensrum' },
	drop_in_meeting_room: {
		icon: WaitingRoomLampIcn,
		name: 'Drop-in-rum',
	},
	cafeteria: { icon: RestaurantForkKnifeIcn, name: 'Cafeteria' },
}

type ServicesIconsProps = { services: string[] }

export const ServicesIcons: ChakraComponent<'ul', ServicesIconsProps> = ({
	services,
	...rest
}) => {
	const { t } = useTranslation()
	const result = useQuery(servicesQuery)
	const [_isLargerSm, isLargerThanMd, _isLargerThanLg] = useMediaQuery([
		'(min-width: 30em)',
		'(min-width: 48em)',
		'(min-width: 62em)',
	])
	const MAX_NUMBER_OF_SERVICES = isLargerThanMd ? 9 : 6
	const { isOpen, onToggle } = useDisclosure()
	if (result.isLoading || result.isIdle) {
		return <Skeleton h={7}></Skeleton>
	}
	if (result.isError) {
		return <Text>{result.error.message}</Text>
	}
	const activeServices = result.data
		.filter((s) => services.indexOf(s.id) > -1)
		.map((s) => s.name)
	const elements = activeServices.map((key) => {
		const service = SERVICES[key]
		if (!service) {
			return null
		}
		return (
			<Flex key={key} align="center">
				<Icon as={service.icon} color="blue.500" h={7} w={7} mr={2.5} />
				<Text color="grey.500">{t(service.name)}</Text>
			</Flex>
		)
	})
	return (
		<Box>
			<Flex gap="20px" wrap="wrap" {...rest}>
				{elements.length > MAX_NUMBER_OF_SERVICES
					? elements.slice(0, MAX_NUMBER_OF_SERVICES)
					: elements}
				{isOpen && elements.slice(MAX_NUMBER_OF_SERVICES)}
			</Flex>
			{elements.length > MAX_NUMBER_OF_SERVICES && (
				<Button
					variant="link"
					color="blue.500"
					size="sm"
					display="block"
					ml="auto"
					mr={1}
					mt={3}
					onClick={() => onToggle()}
					width={{
						base: '100%',
						md: 'min-content',
					}}
				>
					{isOpen ? t('Visa mindre') : t('Visa mer')}
				</Button>
			)}
		</Box>
	)
}
