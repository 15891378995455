import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export function useTranslationKey(
	page: string | undefined,
	ns: string
): string | undefined {
	const { i18n } = useTranslation(ns)
	return useMemo(() => {
		if (!page) {
			return undefined
		}
		const translations = i18n.getResourceBundle(i18n.language, ns) as {
			[key: string]: string
		}
		return Object.keys(translations).find((key) => translations[key] == page)
	}, [i18n, page, ns])
}
