import { memo, useMemo, useEffect, useCallback } from 'react'
import type { FC } from 'react'
import {
	Heading,
	Box,
	Text,
	Flex,
	LinkOverlay,
	CloseButton,
	Link as ChakraLink,
	Grid,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import type { LocationDescriptor } from 'history'
import { displayAddress, formatInt } from '@alexanderathoodly/data-models'
import type { CoworkingLocation } from '@alexanderathoodly/data-models'
import { SIZE_290, SIZE_410 } from '@yta/image-proxy'
import {
	ListResult,
	ListResultHeader,
	ListResultIllustration,
	ListResultActions,
	Carousel,
	CarouselBackArrow,
	CarouselForwardArrow,
	CarouselSlide,
	PinIcn,
	Tag,
	convertChakraColorToCSS,
	mediaQuerybreakpoints as mq,
} from '@alexanderathoodly/ui-library'
import { useCoworkingSerpParams } from './utils'
import { GAAction, sendGA4Event } from '~/analytics/ga'
import { ImgProxy } from '~/utils/imgproxy'
import { css } from '@emotion/react'
import { FILTER_TYPE_MEMBERSHIP, FILTER_TYPE_OFFICE } from './urlGenerator'
import { useTranslation, Trans } from 'react-i18next'

const useLocationTo: (
	location: CoworkingLocation
) => LocationDescriptor<unknown> = (location) => {
	const { t } = useTranslation()
	const searchParams = useCoworkingSerpParams()
	const targetParams = new URLSearchParams()
	if (searchParams.workspaces) {
		targetParams.append('ws', searchParams.workspaces.toString())
	}
	if (searchParams.budget) {
		targetParams.append('budget', searchParams.budget.toString())
	}
	if (searchParams.type) {
		targetParams.append('type', searchParams.type)
	}
	const pathBase = location.is_flex_office
		? t('/kontor', { ns: 'url' })
		: t('/kontorshotell', { ns: 'url' })
	return {
		pathname: `${pathBase}/${location.url}`,
		search: targetParams.toString(),
	}
}

const coworkingSolutionSerpCardCss = {
	heading: css({
		fontSize: '1.5rem !important',
		fontWeight: '600',
		color: convertChakraColorToCSS('grey.800'),
		display: '-webkit-box',
		WebkitLineClamp: 1,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		WebkitBoxOrient: 'vertical',
	}),
	addressInfo: {
		self: css({ alignItems: 'center' }),
		pin: {
			outer: {
				fill: 'blue.500',
				stroke: 'blue.500',
			},
			inner: { fill: 'white' },
			pinCss: css({ height: '1rem', width: '1rem', marginRight: '0.25rem' }),
		},
		address: css({
			color: convertChakraColorToCSS('grey.500'),
			marginTop: '0.5rem',
			marginBottom: '0.25rem',
		}),
	},
	highlights: css({ flexDirection: 'row', gap: '0.8rem' }),
	summary: {
		self: css({
			marginBottom: '1.5rem',
			gridTemplateRows: 'repeat(2, auto)',
			color: convertChakraColorToCSS('grey.500'),
		}),
		solutionInfo: css({
			gridRow: '2/3',
			flexDirection: 'column',
			justifyContent: 'space-between',
			marginTop: '0.75rem',
			marginBottom: 0,
			gap: '0.5rem',
			[mq[1] as string]: {
				gridRow: '1/2',
				marginTop: 0,
				marginBottom: '0.5rem',
			},
		}),
		description: css({
			gridRow: '1/2',
			[mq[1] as string]: {
				gridRow: '2/3',
				display: '-webkit-box',
				WebkitLineClamp: 3,
				textOverflow: 'ellipsis',
				overflow: 'hidden',
				WebkitBoxOrient: 'vertical',
			},
		}),
	},
}

const SummaryDisplay: FC<{ result: CoworkingLocation }> = ({ result }) => {
	const { t, i18n } = useTranslation()
	const { summary } = coworkingSolutionSerpCardCss
	const { workspaces, type } = useCoworkingSerpParams()
	const { min_rent_membership, min_rent_solution, min_workspace_rent } = result

	const priceUnit = workspaces ? t('kr/mån') : t('kr/person/mån')

	const officePrice = useMemo(() => {
		const price = min_rent_solution
			? workspaces
				? min_rent_solution.price
				: min_workspace_rent || 0
			: 0
		return formatInt(Math.round(price / 100) * 100)
	}, [workspaces, min_rent_solution, min_workspace_rent])

	const displayMembershipInfo = useMemo(() => {
		if (type && type === FILTER_TYPE_OFFICE) {
			return false
		}
		if (!type && ((workspaces && workspaces > 9) || !min_rent_membership)) {
			return false
		}
		return true
	}, [workspaces, min_rent_membership, type])

	const membershipText = useMemo(() => {
		const price =
			workspaces && min_rent_membership
				? min_rent_membership.price * workspaces
				: min_rent_membership?.price || 0

		if (price === 0 && type && type !== FILTER_TYPE_OFFICE) {
			return t('Anläggningen saknar medlemskap')
		}
		if (workspaces && workspaces > 9 && type && type !== FILTER_TYPE_OFFICE) {
			return t(
				'För {{workspaces}}st {{min_rent_membership_name}}medlemskap - begär offert',
				{
					workspaces,
					min_rent_membership_name: min_rent_membership?.name || '',
				}
			)
		}
		return workspaces ? (
			<>
				{t('{{workspaces}}st medlemskap från', { workspaces })}{' '}
				<b>{formatInt(price)}</b> {priceUnit}
			</>
		) : (
			<>
				{t('Medlemskap från')} <b>{formatInt(price)}</b> {priceUnit}
			</>
		)
	}, [workspaces, min_rent_membership, priceUnit, type, t])

	return (
		<Grid css={summary.self}>
			<Flex css={summary.solutionInfo} direction="column">
				{result.is_flex_office ? (
					<>
						<Flex justifyContent="space-between">
							{result.available_flex_offices &&
							result.available_flex_offices > 0 ? (
								<>
									<Text>
										{result.min_flex_office_ws.max_workspaces ==
										result.max_flex_office_ws.max_workspaces ? (
											<Trans
												i18nKey="Egna kontor från <bold>{{min_flex_office_ws}}</bold> arbetsplatser"
												values={{
													min_flex_office_ws:
														result.min_flex_office_ws.max_workspaces,
												}}
												components={{
													bold: <b />,
												}}
											/>
										) : (
											<Trans
												i18nKey="Egna kontor för mellan <bold>{{min_flex_office_ws}} - {{max_flex_office_ws}}</bold> arbetsplatser"
												values={{
													min_flex_office_ws:
														result.min_flex_office_ws.max_workspaces,
													max_flex_office_ws:
														result.max_flex_office_ws.max_workspaces,
												}}
												components={{
													bold: <b />,
												}}
											/>
										)}
									</Text>
									<Text>
										{result.min_flex_office_size.size ==
										result.max_flex_office_size.size ? (
											<Trans
												i18nKey="Egna kontor från <bold>{{size}}kvm</bold>"
												values={{ size: result.min_flex_office_size.size }}
												components={{
													bold: <b />,
												}}
											/>
										) : (
											<Trans
												i18nKey="Egna kontor mellan <bold>{{min_size}} - {{max_size}}kvm</bold>"
												values={{
													min_size: result.min_flex_office_size.size,
													max_size: result.max_flex_office_size.size,
												}}
												components={{
													bold: <b />,
												}}
											/>
										)}
									</Text>
								</>
							) : (
								<Text>
									{t(
										'Inga tillgängliga kontor som matchar ditt filter för tillfället'
									)}
								</Text>
							)}
						</Flex>
					</>
				) : (
					<>
						<Flex
							justifyContent="space-between"
							display={type !== FILTER_TYPE_MEMBERSHIP ? 'flex' : 'none'}
						>
							<Text itemProp="priceRange">
								{result.solutions_count > 0 ? (
									<>
										{t('Kontor från')} <b>{officePrice}</b> {priceUnit}
									</>
								) : (
									t(
										'Inga tillgängliga kontorslösningar som matchar ditt filter för tillfället'
									)
								)}
							</Text>
							{(workspaces !== undefined || type === FILTER_TYPE_OFFICE) &&
								min_rent_solution && (
									<Text itemProp="priceRange">
										{t('Storlek från')} <b>{min_rent_solution.size}</b> m²
									</Text>
								)}
						</Flex>
						<Flex
							justifyContent="space-between"
							display={displayMembershipInfo ? 'flex' : 'none'}
						>
							<Text>{membershipText}</Text>
							{(workspaces !== undefined ||
								type === FILTER_TYPE_MEMBERSHIP) && (
								<Text>{min_rent_membership?.name || ''}</Text>
							)}
						</Flex>
					</>
				)}
			</Flex>
			<Text itemProp="description" css={summary.description}>
				{i18n.language != 'en'
					? result.short_description
					: result.short_description_en}
			</Text>
		</Grid>
	)
}

export const CoworkingSolutionSerpCard: FC<{
	result: CoworkingLocation
	locationIndex: number
	isFeaturedListingCard?: boolean
}> = ({ result, locationIndex, isFeaturedListingCard = false }) => {
	const { t } = useTranslation()
	const to = useLocationTo(result)
	const sendGAAction = useCallback(
		(action: 'view' | 'click') => {
			GAAction({
				category: 'site_interaction',
				action: action,
				label: 'featuredListing',
				metrics: {
					metric2: result.id,
					metric3: result.provider_name,
				},
			})
			sendGA4Event('featured_listing', {
				type: action,
				id: result.id,
				provider_name: result.provider_name,
			})
		},
		[result.id, result.provider_name]
	)
	useEffect(() => {
		if (isFeaturedListingCard) {
			sendGAAction('view')
		}
	}, [isFeaturedListingCard, sendGAAction])
	const { heading, addressInfo, highlights } = coworkingSolutionSerpCardCss
	const solutionCount = result.is_flex_office
		? result.available_flex_offices
		: result.solutions_count

	return (
		<ListResult
			isPremium={result.is_premium_listing}
			itemScope
			itemType="https://schema.org/LocalBusiness"
		>
			<ListResultHeader>
				<LinkOverlay
					as={Link}
					to={to}
					onClick={() => {
						if (isFeaturedListingCard) {
							sendGAAction('click')
						}
					}}
				>
					<Flex>
						<Heading itemProp="name" css={heading}>
							{result.name}
						</Heading>
						<ImgProxy
							src={result.provider_logo}
							sizeSet={{ base: SIZE_290 }}
							loading={locationIndex > 3 ? 'lazy' : 'eager'}
							alt={`${result.provider_name} - logo`}
							ml="auto"
							maxH="3.6rem"
							maxW="3.6rem"
							display={result.is_premium_listing ? 'inline-flex' : 'none'}
						/>
					</Flex>
				</LinkOverlay>
				<Flex
					itemProp="address"
					itemScope
					itemType="https://schema.org/PostalAddress"
					css={coworkingSolutionSerpCardCss.addressInfo.self}
				>
					<PinIcn
						outer={addressInfo.pin.outer}
						inner={addressInfo.pin.inner}
						css={addressInfo.pin.pinCss}
					/>
					<Text css={addressInfo.address}>
						{displayAddress(result.address).replace(
							',',
							`, ${result.breadcrumbs.area.name}, `
						)}
					</Text>
					<meta itemProp="addressLocality" content={result.address.locator} />
					<meta itemProp="addressRegion" content={result.address.city} />
					<meta itemProp="streetAddress" content={result.address.street} />
				</Flex>
				<Flex css={highlights}>
					{result.highlights.map((highlight, index) => (
						<Tag
							key={`serpcard-${result.id}-${index}`}
							color="light-blue"
							size="font12"
						>
							{t(highlight)}
						</Tag>
					))}
					{solutionCount > 0 && (
						<Box display={{ base: 'none', lg: 'flex' }}>
							<Tag
								key={`serpcard-${result.id}-solution-count`}
								color="light-blue"
								size="font12"
							>
								<Text>
									<b>{solutionCount}</b>{' '}
									{t('ledigt kontor just nu', {
										count: solutionCount,
									})}
								</Text>
							</Tag>
						</Box>
					)}
				</Flex>
			</ListResultHeader>
			<ListResultIllustration>
				<Box position="relative" zIndex={-1} maxH="max-content" maxW="25rem">
					<Box
						position="absolute"
						top={3}
						left={3}
						px={3}
						py={1}
						borderRadius="sm"
						backgroundColor="#EBC22A"
						display={isFeaturedListingCard ? 'unset' : 'none'}
					>
						{t('Betald placering')}
					</Box>
					<ImgProxy
						src={result.images[0] || ''}
						sizeSet={{ base: SIZE_410 }}
						loading={locationIndex > 3 ? 'lazy' : 'eager'}
						alt={t('kontorshotell i {{city}} - {{location_name}}', {
							ns: 'meta_data',
							city: result.address.city,
							location_name: result.name,
						})}
						itemProp="image"
					/>
					{solutionCount > 0 && (
						<Box
							position="absolute"
							bottom={3}
							right={3}
							px={3}
							py={1}
							borderRadius="sm"
							backgroundColor="blue.50"
							display={{ lg: 'none' }}
						>
							<Text>
								<b>{solutionCount}</b>{' '}
								{solutionCount > 1 ? t('lediga') : t('ledigt')}{' '}
								{t('kontor just nu')}
							</Text>
						</Box>
					)}
					<Grid
						gridTemplateRows="1fr"
						gridTemplateColumns="1fr 1fr"
						gap=".3rem"
						mt=".3rem"
						display={
							result.is_premium_listing && result.images.length > 1
								? 'grid'
								: 'none'
						}
					>
						<ImgProxy
							src={result.images[1] || ''}
							sizeSet={{ base: SIZE_290 }}
							loading={locationIndex > 3 ? 'lazy' : 'eager'}
							alt={t('kontorshotell i {{city}} - {{location_name}}', {
								ns: 'meta_data',
								city: result.address.city,
								location_name: result.name,
							})}
							itemProp="image"
						/>
						<ImgProxy
							src={result.images[2] || ''}
							sizeSet={{ base: SIZE_290 }}
							loading={locationIndex > 3 ? 'lazy' : 'eager'}
							alt={t('kontorshotell i {{city}} - {{location_name}}', {
								ns: 'meta_data',
								city: result.address.city,
								location_name: result.name,
							})}
							itemProp="image"
						/>
					</Grid>
				</Box>
			</ListResultIllustration>
			<ListResultActions>
				<SummaryDisplay result={result} />
				<Box
					px={3}
					py={1}
					borderRadius="sm"
					backgroundColor="#EBC22A"
					display={result.is_premium_listing ? 'inline-flex' : 'none'}
				>
					{t('Premium')}
				</Box>
			</ListResultActions>
			<meta itemProp="additionalType" content={'Coworking office'} />
			<meta itemProp="url" content={result.url} />
			<meta itemProp="brand" content={result.provider_name} />
			<meta
				itemProp="keywords"
				content={result.highlights.map((highlight) => t(highlight)).toString()}
			/>
			<meta
				itemProp="tourBookingPage"
				content={`https://yta.se/kontorshotell/${result.url}`}
			/>
		</ListResult>
	)
}

export const CoworkingSolutionMapCard: FC<{
	result: CoworkingLocation
	onClickEvent?: () => void
}> = memo(({ result, onClickEvent }) => {
	const { t } = useTranslation()
	const to = useLocationTo(result)
	const carouselSlides = result.images.map((img, index) => (
		<CarouselSlide key={img}>
			<ImgProxy
				src={img}
				sizeSet={{ base: SIZE_290 }}
				h={20}
				w="min-content"
				loading="lazy"
				alt={`${result.name} bild ${index + 1}`}
			/>
		</CarouselSlide>
	))
	const solutionCount = result.is_flex_office
		? result.available_flex_offices
		: result.solutions_count
	return (
		<Box
			position="absolute"
			bottom={5}
			left={{ base: 1, md: 5 }}
			p={{ base: 5, md: 7 }}
			w={{ base: 'xs', md: 'lg', lg: 'xl' }}
			bg="white"
			borderColor="grey.100"
			borderStyle="solid"
			borderWidth="thin"
			borderRadius="base"
			zIndex={5}
		>
			<Flex mb={3} alignItems="center">
				<ChakraLink
					as={Link}
					to={to}
					target="_blank"
					variant="black"
					flex={1}
					overflow="hidden"
				>
					<Heading
						fontSize={{ base: 'sm', md: 'xl', lg: '2xl' }}
						fontWeight="semibold"
						whiteSpace="nowrap"
					>
						{result.name}
					</Heading>
				</ChakraLink>
				<CloseButton
					aria-label="Close Solution Card"
					size="md"
					ml={6}
					onClick={onClickEvent}
				/>
			</Flex>
			<Flex
				borderBottom="thin dashed"
				borderColor="grey.100"
				pb={6}
				mb={5}
				gap="19px"
			>
				<Carousel>
					<CarouselBackArrow
						onClick={() =>
							GAAction({
								category: 'site_interaction',
								action: 'carousel_navigation',
							})
						}
					/>
					<CarouselForwardArrow
						onClick={() =>
							GAAction({
								category: 'site_interaction',
								action: 'carousel_navigation',
							})
						}
					/>
					{carouselSlides}
				</Carousel>
				<Flex flexDirection="column">
					<Flex alignItems="center">
						<PinIcn
							outer={coworkingSolutionSerpCardCss.addressInfo.pin.outer}
							inner={coworkingSolutionSerpCardCss.addressInfo.pin.inner}
							css={coworkingSolutionSerpCardCss.addressInfo.pin.pinCss}
						/>
						<Text color="grey.500">
							{displayAddress(result.address).replace(
								',',
								`, ${result.breadcrumbs.area.name}, `
							)}
						</Text>
					</Flex>
					<Flex gap="0.5rem" flexDirection={{ base: 'column', md: 'row' }}>
						{result.highlights.map((highlight, index) => (
							<Tag
								key={`serpcard-${result.id}-${index}`}
								color="light-blue"
								size="font12"
							>
								{t(highlight)}
							</Tag>
						))}
					</Flex>
				</Flex>
			</Flex>
			{solutionCount > 0 && (
				<Text color="grey.500">
					<b>{solutionCount}</b> {solutionCount > 1 ? t('lediga') : t('ledigt')}{' '}
					{t('kontor just nu')}
				</Text>
			)}
			<SummaryDisplay result={result} />
		</Box>
	)
})

CoworkingSolutionMapCard.displayName = 'CoworkingSolutionMapCard'
